import styled, { useTheme } from 'styled-components/macro';
import { Modal } from 'react-responsive-modal';
import { IoMdClose } from 'react-icons/io';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { hideInsufficientFundsModal } from 'reducers/modalsControllerSlice';

import InsufficientFunds from 'assets/images/insufficient-funds.png';

const InsufficientFundsModalContentWrapper = styled.div`
  padding: 30px 48px;

  img {
    height: 48px;
    margin-bottom: 13px;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: #ffffff;
    margin-bottom: 13px;
  }

  p {
    width: 229px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #989da8;

    margin-bottom: 20px;
  }

  button {
    width: 100%;
    height: 40px;

    background: #2c8dff;
    border-radius: 5px;

    border: none;
    outline: none;
    cursor: pointer;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #ffffff;
  }
`;

const InsufficientFundsModal = (): JSX.Element => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { showInsufficientFundsModal, sufficientFundsAmount } = useAppSelector(
    (state) => state.modalsController
  );

  return (
    <Modal
      open={showInsufficientFundsModal}
      onClose={() => dispatch(hideInsufficientFundsModal())}
      showCloseIcon={false}
      center
      styles={{
        overlay: {
          backdropFilter: 'blur(5px)',
        },
        modalContainer: {
          background: 'rgba(255, 255, 255, 0.1)',
        },
        modal: {
          padding: 0,
          background: theme.background[100],
          color: theme.text[200],
          width: '80%',
          maxWidth: '364px',
          borderRadius: '10px',
        },
      }}
      closeIcon={<IoMdClose style={{ width: 32, height: 32 }} color="white" />}
    >
      <InsufficientFundsModalContentWrapper>
        <img src={InsufficientFunds} alt="" />
        <h3>Insuffiecient Funds</h3>
        <p>
          Please top up your wallet with at least {sufficientFundsAmount} ETH to
          proceed,
        </p>
        <button onClick={() => dispatch(hideInsufficientFundsModal())}>
          Okay Got it
        </button>
      </InsufficientFundsModalContentWrapper>
    </Modal>
  );
};

export default InsufficientFundsModal;
