import styled from 'styled-components/macro';

const EventBoxDataWrapper = styled.div`
  th:first-of-type,
  td:first-of-type {
    text-align: left;
  }
  th:last-of-type,
  td:last-of-type {
    text-align: right;
  }
`;

interface EventBoxDataProps {
  eventData: any;
}

const EventBoxData = ({ eventData }: EventBoxDataProps): JSX.Element => {
  return (
    <EventBoxDataWrapper>
      <table>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Chance</th>
            <th>Value</th>
          </tr>
          {eventData.map((data: any, i: number) => (
            <tr key={i}>
              <td>{data.name}</td>
              <td>{data.chance}%</td>
              <td>${data.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </EventBoxDataWrapper>
  );
};

export default EventBoxData;
