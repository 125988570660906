import styled from 'styled-components/macro';
import Select from 'react-dropdown-select';

const StyledSelect = styled(Select)`
  /* border: 1.31579px solid red;
  border-radius: 2px !important;
  padding: 15px 20px !important;
  z-index: 1000 !important; */
  border: 1px solid #ffffff;
  border-radius: 8px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 140% */
  background: #000;
  color: #ffffff;
  padding: 8px;

  font-weight: bold !important;

  &:focus-within {
    border-color: #fff;
  }

  .react-dropdown-select {
    /* white-space: nowrap;
    display: flex;
    align-items: center;
    margin: auto; */
    border: 1px solid #ffffff;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: none;
    border-radius: 8px !important;
    /* identical to box height, or 140% */
    background: #000;
    color: #ffffff;
    padding: 8px;
  }

  .react-dropdown-select-content {
    & > span {
      font-family: Inter;
      font-weight: bold;
      font-size: 16px;
      z-index: 1111111;
      line-height: 24px;
      letter-spacing: -0.18px;
      color: #fff;
    }
  }

  .react-dropdown-select-dropdown-handle {
    & > svg {
      width: 24px;
      height: 24px;
      color: #fff;
    }
  }

  .react-dropdown-select-dropdown {
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    color: #0c0c0c;

    .react-dropdown-select-item {
      font-size: 12px;
      line-height: 112.02%;
      color: #0c0c0c;
      &:hover {
        background: #0c0c0c;
        opacity: 0.05;
        border-radius: 8px;
      }

      &.react-dropdown-select-item-selected {
        background: #000;
        color: #fff;
      }
    }
  }
`;

export default StyledSelect;
