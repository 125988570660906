import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { getUnixTime, format } from 'date-fns';

import { useGetMysteryBoxesQuery } from 'generated/graphql';

import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
import CarouselElement from 'components/common/CarouselElement';
import ArtistCard from 'components/common/cards/ArtistCard';
import Button from 'components/common/Button';
import UpgradeLevelCard from 'components/common/cards/UpgradeLevelCard';

// import { mysteryBoxesData } from 'data/mysteryBoxesData';

const HomeWrapper = styled.div`
  padding-bottom: 40px;
  .filters-wrapper {
    padding-left: 24px;
    column-gap: 8px;
    overflow-x: auto;
    margin-top: 40px;

    .active {
      background: #ffcc00;
    }
  }

  .artists-wrapper {
    margin-top: 40px;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

// const images = [
//   {
//     original:
//       'https://images.unsplash.com/photo-1521394095254-d70a3fe40353?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1703&q=80',
//     thumbnail:
//       'https://images.unsplash.com/photo-1521394095254-d70a3fe40353?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1703&q=80',
//   },
//   {
//     original:
//       'https://images.unsplash.com/photo-1521337581100-8ca9a73a5f79?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1677&q=80',
//     thumbnail:
//       'https://images.unsplash.com/photo-1521337581100-8ca9a73a5f79?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1677&q=80',
//   },
//   {
//     original:
//       'https://images.unsplash.com/photo-1521198022873-af0f772bf653?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1737&q=80',
//     thumbnail:
//       'https://images.unsplash.com/photo-1521198022873-af0f772bf653?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1737&q=80',
//   },
//   {
//     original:
//       'https://images.unsplash.com/photo-1517305542662-2dd61accb41d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
//     thumbnail:
//       'https://images.unsplash.com/photo-1517305542662-2dd61accb41d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
//   },
//   {
//     original:
//       'https://images.unsplash.com/photo-1530192742375-0cbabc8953a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1636&q=80',
//     thumbnail:
//       'https://images.unsplash.com/photo-1530192742375-0cbabc8953a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1636&q=80',
//   },
// ];

interface HomeProps {}

const Home = (_: HomeProps): JSX.Element => {
  const navigate = useNavigate();

  const { data } = useGetMysteryBoxesQuery({
    variables: {
      where: {
        end_at: {
          _gt: format(
            new Date(getUnixTime(new Date()) * 1000),
            "yyyy-MM-dd'T'HH:mm:ss"
          ),
        },
        published: { _eq: true },
        left_items_count: { _gt: 0 },
      },
    },
  });

  return (
    <HomeWrapper>
      <div className="carousel-element">
        <CarouselElement
          // images={mysteryBoxesData.map((mysteryBox) => ({
          //   original: mysteryBox.asset,
          //   thumbnai: mysteryBox.asset,
          // }))}
          mysteryBoxes={data?.mystery_box ?? []}
        />
      </div>
      <FlexRowWrapper className="filters-wrapper">
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
          className="active"
        >
          All
        </Button>
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
        >
          Featured
        </Button>
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
        >
          Trending
        </Button>
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
        >
          Upcoming
        </Button>
      </FlexRowWrapper>
      <FlexRowWrapper className="artists-wrapper">
        {data?.mystery_box.map((mysteryBox, i) => (
          <ArtistCard
            key={i}
            name={mysteryBox?.celebrity || mysteryBox?.title || 'Maroon 5'}
            onBuyNowClick={() => navigate(`/event/${mysteryBox?.id}`)}
            profileImage={mysteryBox?.file_url}
          />
        ))}
      </FlexRowWrapper>
      <div style={{ padding: '24px 24px 0 24px' }}>
        <UpgradeLevelCard />
      </div>
    </HomeWrapper>
  );
};

export default Home;
