import ethers from 'ethers';
import { redeemOnEth } from '@certusone/wormhole-sdk/lib/esm/nft_bridge/redeem';

async function redeemOnEthUtil(
  signedVAA: Uint8Array,
  signer: ethers.Signer,
  nftBridgeAddress: string
): Promise<ethers.ContractReceipt> {
  return redeemOnEth(nftBridgeAddress, signer, signedVAA);
}

export { redeemOnEthUtil };
