import { TorusConnector } from '@web3-react/torus-connector';
import config from 'config';

import { chainNetworkInfo } from '../chainNetworkInfo';

export const torusConnector = (chainId: number = config.chainId) =>
  new TorusConnector({
    chainId,
    initOptions: {
      useLocalStorage: true,
      network: {
        ...chainNetworkInfo[chainId],
      },
      // showTorusButton: false,
      whiteLabel: {
        theme: {
          isDark: true,
          colors: {
            torusBrand1: '#FFCC00',
          },
        },
        logoDark:
          'https://res.cloudinary.com/dm46qh3th/image/upload/v1650882400/shape_w8ygfz.png',
        logoLight:
          'https://res.cloudinary.com/dm46qh3th/image/upload/v1650882400/shape_w8ygfz.png',
        topupHide: false,
        featuredBillboardHide: true,
        disclaimerHide: true,
        defaultLanguage: 'en',
        tncLink: '',
        privacyPolicy: '',
        contactLink: '',
      },
    },
  });
