import styled from 'styled-components/macro';
import { FaEllipsisH } from 'react-icons/fa';

import FlexColumnWrapper from '../wrappers/FlexColumnWrapper';
import FlexRowWrapper from '../wrappers/FlexRowWrapper';
import Button from '../Button';
import CustomDropdown from 'components/CustomDropdown';

const ArtistCardWrapper = styled(FlexColumnWrapper)`
  background: #ffffff;
  border-radius: 16px;
  width: 152px;
  /* height: 218px; */
  row-gap: 8px;

  .artist-image {
    width: 152px;
    height: 152px;
    overflow: hidden;
    border-radius: 16px;

    img {
      width: 152px;
      height: 152px;
      object-fit: cover;
    }
  }

  .t-artist-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 112.02%;
    color: #000000;
    border-radius: 16px;
    margin: 0 14px;
  }

  .cta-wrapper {
    padding: 0 12px;
    align-items: center;
    gap: 12px;
  }
  .buy-now-button {
    margin: auto;
    margin-bottom: 8px;
    width: 90%;
  }
  .more-icon {
    text-align: center;
  }
`;

interface ArtistCardProps {
  profileImage: string;
  name: string;
  onBuyNowClick: () => void;
  isUser?: boolean;
  onSwapChainClick?: () => void;
}

const ArtistCard = ({
  profileImage,
  name,
  onBuyNowClick,
  isUser,
  onSwapChainClick,
}: ArtistCardProps): JSX.Element => {
  return (
    <ArtistCardWrapper>
      <div className="artist-image">
        <img src={profileImage} alt={name} />
      </div>
      <div className="t-artist-name">{name}</div>
      <FlexRowWrapper className="cta-wrapper">
        <Button
          className="buy-now-button"
          type="secondary"
          onClick={onBuyNowClick}
        >
          {isUser ? 'View' : 'Buy Now'}
        </Button>

        {isUser && (
          <CustomDropdown
            labelElement={<FaEllipsisH />}
            optionsElement={
              <>
                {/* <div className="option">Burn</div>
                <div className="option" onClick={() => {}}>
                  Withdraw
                </div> */}
                <div className="option" onClick={onSwapChainClick}>
                  Swap Chain
                </div>
              </>
            }
          />
          // <div className="more-icon" onClick={() => console.log('more click')}>
          //   <FaEllipsisH />
          // </div>
        )}
      </FlexRowWrapper>
    </ArtistCardWrapper>
  );
};

export default ArtistCard;
