import * as React from 'react';
import styled from 'styled-components/macro';
import { BiWallet } from 'react-icons/bi';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';

import { torusConnector } from 'utils/web3/connectors/torusConnector';

import Button from 'components/common/Button';
import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';

import { ReactComponent as BackArrow } from 'assets/arrow-back.svg';

const HeaderWrapper = styled(FlexRowWrapper)`
  justify-content: space-evenly;
  padding-top: 16px;
  align-items: center;
  background: linear-gradient(
    180deg,
    #0c0c0c44 40%,
    rgba(168, 163, 158, 0) 100%
  );

  .input-wrapper {
    input {
      width: 216px;
      height: 32px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 16px;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
  .profile-image {
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #ffffff;
    }
  }
`;

interface HeaderProps {}

const Header = (_: HeaderProps): JSX.Element => {
  const { active, library, activate, deactivate } = useWeb3React();
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (active) {
  //     (async () => {
  //       try {
  //         // const userInfo = torusConnector().torus.getUserInfo();
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     })();
  //   }
  // }, [active, library]);

  const handleTorusConnectClick = async () => {
    try {
      if (!active) {
        setTimeout(() => {
          activate(torusConnector(), console.error);
        }, 500);
      }
    } catch (error: any) {
      console.error(error);
      deactivate();
      toast.error(
        error?.message ??
          error?.[0]?.message ??
          'Something went wrong: ' + JSON.stringify(error)
      );
    }
  };

  return (
    <HeaderWrapper>
      <Button onClick={() => navigate(-1)} type="primary">
        <BackArrow />
      </Button>
      <div className="input-wrapper">
        <input type="text" />
      </div>
      {active ? (
        <div className="profile-image">
          <Link to="/user">
            <img
              src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
              alt="profile"
            />
          </Link>
        </div>
      ) : (
        <BiWallet
          color="white"
          style={{ width: 32, height: 32, cursor: 'pointer' }}
          onClick={handleTorusConnectClick}
        />
      )}
    </HeaderWrapper>
  );
};

export default Header;
