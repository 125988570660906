import {
  ChainId,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_ETHEREUM_ROPSTEN,
  CHAIN_ID_POLYGON,
} from '@certusone/wormhole-sdk';

import bscIcon from 'assets/icons/bsc.svg';
import ethIcon from 'assets/icons/eth.svg';
import polygonIcon from 'assets/icons/polygon.svg';

export interface ChainInfo {
  id: ChainId;
  name: string;
  logo: string;
}

export const getChains = (isTestnet = false) =>
  !isTestnet
    ? [
        {
          id: CHAIN_ID_BSC,
          name: 'Binance Smart Chain',
          logo: bscIcon,
        },
        {
          id: CHAIN_ID_ETH,
          name: 'Ethereum',
          logo: ethIcon,
        },
        {
          id: CHAIN_ID_POLYGON,
          name: 'Polygon',
          logo: polygonIcon,
        },
      ]
    : [
        {
          id: CHAIN_ID_BSC,
          name: 'Binance Smart Chain',
          logo: bscIcon,
        },
        {
          id: CHAIN_ID_ETH,
          name: 'Ethereum (Goerli)',
          logo: ethIcon,
        },
        {
          id: CHAIN_ID_ETHEREUM_ROPSTEN,
          name: 'Ethereum (Ropsten)',
          logo: ethIcon,
        },
        {
          id: CHAIN_ID_POLYGON,
          name: 'Polygon',
          logo: polygonIcon,
        },
      ];

export const getChainsWithNftSupport = getChains().filter(
  ({ id }) =>
    id === CHAIN_ID_BSC ||
    id === CHAIN_ID_ETH ||
    id === CHAIN_ID_ETHEREUM_ROPSTEN ||
    id === CHAIN_ID_POLYGON
);

export type ChainsById = { [key in ChainId]: ChainInfo };

export const getChainsById = (isTestnet = false) =>
  getChains(isTestnet).reduce((obj, chain) => {
    obj[chain.id] = chain;
    return obj;
  }, {} as ChainsById);

export const getDefaultNativeCurrencySymbol = (chainId: ChainId) =>
  chainId === CHAIN_ID_ETH || chainId === CHAIN_ID_ETHEREUM_ROPSTEN
    ? 'ETH'
    : chainId === CHAIN_ID_BSC
    ? 'BNB'
    : chainId === CHAIN_ID_POLYGON
    ? 'MATIC'
    : '';

export const getExplorerName = (chainId: ChainId) =>
  chainId === CHAIN_ID_ETH || chainId === CHAIN_ID_ETHEREUM_ROPSTEN
    ? 'Etherscan'
    : chainId === CHAIN_ID_BSC
    ? 'BscScan'
    : chainId === CHAIN_ID_POLYGON
    ? 'Polygonscan'
    : 'Explorer';

export const MAINNET_WORMHOLE_RPC_HOSTS = [
  'https://wormhole-v2-mainnet-api.certus.one',
  'https://wormhole.inotel.ro',
  'https://wormhole-v2-mainnet-api.mcf.rocks',
  'https://wormhole-v2-mainnet-api.chainlayer.network',
  'https://wormhole-v2-mainnet-api.staking.fund',
  'https://wormhole-v2-mainnet.01node.com',
];

export const TESTNET_WORMHOLE_RPC_HOSTS = [
  'https://wormhole-v2-testnet-api.certus.one',
];

export const MAINNET_ETH_NETWORK_CHAIN_ID = 1;
export const ROPSTEN_ETH_NETWORK_CHAIN_ID = 1;
export const MAINNET_BSC_NETWORK_CHAIN_ID = 56;
export const MAINNET_POLYGON_NETWORK_CHAIN_ID = 137;
export const TESTNET_ETH_NETWORK_CHAIN_ID = 5;
export const TESTNET_ROPSTEN_ETH_NETWORK_CHAIN_ID = 3;
export const TESTNET_BSC_NETWORK_CHAIN_ID = 97;
export const TESTNET_POLYGON_NETWORK_CHAIN_ID = 80001;

export const getEvmChainId = (chainId: ChainId, isTestnet = false) => {
  switch (chainId) {
    case CHAIN_ID_BSC:
      return !isTestnet
        ? MAINNET_BSC_NETWORK_CHAIN_ID
        : TESTNET_BSC_NETWORK_CHAIN_ID;
    case CHAIN_ID_ETHEREUM_ROPSTEN:
      return !isTestnet
        ? ROPSTEN_ETH_NETWORK_CHAIN_ID
        : TESTNET_ROPSTEN_ETH_NETWORK_CHAIN_ID;
    case CHAIN_ID_POLYGON:
      return !isTestnet
        ? MAINNET_POLYGON_NETWORK_CHAIN_ID
        : TESTNET_POLYGON_NETWORK_CHAIN_ID;
    case CHAIN_ID_ETH:
    default:
      return !isTestnet
        ? MAINNET_ETH_NETWORK_CHAIN_ID
        : TESTNET_ETH_NETWORK_CHAIN_ID;
  }
};

export const MAINNET_ETH_CORE_BRIDGE_ADDRESS =
  '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B';
export const MAINNET_ETH_NFT_BRIDGE_ADDRESS =
  '0x6FFd7EdE62328b3Af38FCD61461Bbfc52F5651fE';
export const MAINNET_ETH_TOKEN_BRIDGE_ADDRESS =
  '0x3ee18B2214AFF97000D974cf647E7C347E8fa585';
export const MAINNET_BSC_CORE_BRIDGE_ADDRESS =
  '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B';
export const MAINNET_BSC_NFT_BRIDGE_ADDRESS =
  '0x5a58505a96D1dbf8dF91cB21B54419FC36e93fdE';
export const MAINNET_BSC_TOKEN_BRIDGE_ADDRESS =
  '0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7';
export const MAINNET_POLYGON_CORE_BRIDGE_ADDRESS =
  '0x7A4B5a56256163F07b2C80A7cA55aBE66c4ec4d7';
export const MAINNET_POLYGON_NFT_BRIDGE_ADDRESS =
  '0x90BBd86a6Fe93D3bc3ed6335935447E75fAb7fCf';
export const MAINNET_POLYGON_TOKEN_BRIDGE_ADDRESS =
  '0x5a58505a96D1dbf8dF91cB21B54419FC36e93fdE';

export const TESTNET_ETH_CORE_BRIDGE_ADDRESS =
  '0x706abc4E45D419950511e474C7B9Ed348A4a716c';
export const TESTNET_ETH_NFT_BRIDGE_ADDRESS =
  '0xD8E4C2DbDd2e2bd8F1336EA691dBFF6952B1a6eB';
export const TESTNET_ETH_TOKEN_BRIDGE_ADDRESS =
  '0xF890982f9310df57d00f659cf4fd87e65adEd8d7';
export const ROPSTEN_ETH_CORE_BRIDGE_ADDRESS =
  '0x210c5F5e2AF958B4defFe715Dc621b7a3BA888c5';
export const ROPSTEN_ETH_NFT_BRIDGE_ADDRESS =
  '0x2b048Da40f69c8dc386a56705915f8E966fe1eba';
export const ROPSTEN_ETH_TOKEN_BRIDGE_ADDRESS =
  '0xF174F9A837536C449321df1Ca093Bb96948D5386';
export const TESTNET_BSC_CORE_BRIDGE_ADDRESS =
  '0x68605AD7b15c732a30b1BbC62BE8F2A509D74b4D';
export const TESTNET_BSC_NFT_BRIDGE_ADDRESS =
  '0xcD16E5613EF35599dc82B24Cb45B5A93D779f1EE';
export const TESTNET_BSC_TOKEN_BRIDGE_ADDRESS =
  '0x9dcF9D205C9De35334D646BeE44b2D2859712A09';
export const TESTNET_POLYGON_CORE_BRIDGE_ADDRESS =
  '0x0CBE91CF822c73C2315FB05100C2F714765d5c20';
export const TESTNET_POLYGON_NFT_BRIDGE_ADDRESS =
  '0x51a02d0dcb5e52F5b92bdAA38FA013C91c7309A9';
export const TESTNET_POLYGON_TOKEN_BRIDGE_ADDRESS =
  '0x377D55a7928c046E18eEbb61977e714d2a76472a';

export const getWormholeRpcHosts = (isTestnet = false) => {
  return !isTestnet ? MAINNET_WORMHOLE_RPC_HOSTS : TESTNET_WORMHOLE_RPC_HOSTS;
};

export const getCoreBridgeAddressForChain = (
  chainId: ChainId,
  isTestnet = false
) => {
  switch (chainId) {
    case CHAIN_ID_BSC:
      return !isTestnet
        ? MAINNET_BSC_CORE_BRIDGE_ADDRESS
        : TESTNET_BSC_CORE_BRIDGE_ADDRESS;
    case CHAIN_ID_ETHEREUM_ROPSTEN:
      return !isTestnet
        ? MAINNET_ETH_CORE_BRIDGE_ADDRESS
        : ROPSTEN_ETH_CORE_BRIDGE_ADDRESS;
    case CHAIN_ID_POLYGON:
      return !isTestnet
        ? MAINNET_POLYGON_CORE_BRIDGE_ADDRESS
        : TESTNET_POLYGON_CORE_BRIDGE_ADDRESS;
    case CHAIN_ID_ETH:
    default:
      return !isTestnet
        ? MAINNET_ETH_CORE_BRIDGE_ADDRESS
        : TESTNET_ETH_CORE_BRIDGE_ADDRESS;
  }
};

export const getNftBridgeAddressForChain = (
  chainId: ChainId,
  isTestnet = false
) => {
  switch (chainId) {
    case CHAIN_ID_BSC:
      return !isTestnet
        ? MAINNET_BSC_NFT_BRIDGE_ADDRESS
        : TESTNET_BSC_NFT_BRIDGE_ADDRESS;
    case CHAIN_ID_ETHEREUM_ROPSTEN:
      return !isTestnet
        ? MAINNET_ETH_NFT_BRIDGE_ADDRESS
        : ROPSTEN_ETH_NFT_BRIDGE_ADDRESS;
    case CHAIN_ID_POLYGON:
      return !isTestnet
        ? MAINNET_POLYGON_NFT_BRIDGE_ADDRESS
        : TESTNET_POLYGON_NFT_BRIDGE_ADDRESS;
    case CHAIN_ID_ETH:
    default:
      return !isTestnet
        ? MAINNET_ETH_NFT_BRIDGE_ADDRESS
        : TESTNET_ETH_NFT_BRIDGE_ADDRESS;
  }
};

export const getTokenBridgeAddressForChain = (
  chainId: ChainId,
  isTestnet = false
) => {
  switch (chainId) {
    case CHAIN_ID_BSC:
      return !isTestnet
        ? MAINNET_BSC_TOKEN_BRIDGE_ADDRESS
        : TESTNET_BSC_TOKEN_BRIDGE_ADDRESS;
    case CHAIN_ID_ETHEREUM_ROPSTEN:
      return !isTestnet
        ? MAINNET_ETH_TOKEN_BRIDGE_ADDRESS
        : ROPSTEN_ETH_TOKEN_BRIDGE_ADDRESS;
    case CHAIN_ID_POLYGON:
      return !isTestnet
        ? MAINNET_POLYGON_TOKEN_BRIDGE_ADDRESS
        : TESTNET_POLYGON_TOKEN_BRIDGE_ADDRESS;
    case CHAIN_ID_ETH:
    default:
      return !isTestnet
        ? MAINNET_ETH_TOKEN_BRIDGE_ADDRESS
        : TESTNET_ETH_TOKEN_BRIDGE_ADDRESS;
  }
};
