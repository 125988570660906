import { useAppSelector } from 'hooks/redux/useAppSelector';

import ConnectWalletModal from './modals/ConnectWalletModal';
import IncorrectNetworkModal from './modals/IncorrectNetworkSelected';
import InsufficientFundsModal from './modals/InsufficientFundsModal';
import InteractionBlockingSpinner from './modals/InteractionBlockingSpinner';

const ModalsController = (): JSX.Element => {
  const showInteractionBlockingSpinner = useAppSelector(
    (state) => state.modalsController.showInteractionBlockingModal
  );

  return (
    <>
      <ConnectWalletModal />
      <IncorrectNetworkModal />
      <InsufficientFundsModal />
      {showInteractionBlockingSpinner && <InteractionBlockingSpinner />}
    </>
  );
};

export default ModalsController;
