import styled from 'styled-components/macro';

import FlexColumnWrapper from 'components/common/wrappers/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
// import Button from 'components/common/Button';
import LabelElement from 'components/common/LabelElement';
import StyledSelect from 'components/common/StyledSelect';

// import { ReactComponent as EthIcon } from 'assets/eth.svg';
// import { ReactComponent as BscIcon } from 'assets/bsc.svg';
import { ReactComponent as PolygonIcon } from 'assets/polygon.svg';
import { ReactComponent as GasIcon } from 'assets/gas.svg';

const TargetWrapper = styled.div`
  .next-button {
    width: 312px;
    height: 40px;
    font-weight: 700;
    margin: 20px 0;
    font-size: 16px;
    line-height: 112.02%;
    color: #ffffff;
    background: #0072de;
    border-radius: 42px;
  }
  .t-warning {
    padding: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    row-gap: 8px;
    background: rgba(255, 204, 0, 0.2);
    margin-bottom: 32px;

    border-radius: 16px;
    color: #ffffff;
    .gas-fees-info {
      align-items: center;
      column-gap: 8px;
    }
    .warning-info {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #ffcc00;
    }
  }
  .custom-dropdown-component {
    justify-content: space-between;
    align-items: center;
    .connection-status-button {
      border-radius: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .label-symbol-wrapper {
      align-items: center;
      column-gap: 8px;
    }
  }
  .custom-dropdown-item {
    align-items: center;
    column-gap: 8px;
  }
`;

interface TargetProps {
  accountAddress?: string;
  tokenContractAddress?: string;
  tokenId?: string;
}

const Target = ({
  accountAddress,
  tokenContractAddress,
  tokenId,
}: TargetProps): JSX.Element => {
  return (
    <TargetWrapper>
      <LabelElement
        label="Receive the NFT on the target chain"
        element={
          <StyledSelect
            options={[
              // { icon: <BscIcon />, label: 'Binance Smart Chain', value: 'BSC' },
              // {
              //   icon: <EthIcon />,
              //   label: 'Ethereum Smart Chain',
              //   value: 'ETH',
              // },
              {
                icon: <PolygonIcon />,
                label: 'Polygon Chain',
                value: 'Polygon',
              },
            ]}
            values={[]}
            onChange={(value) => {
              console.log(value);
            }}
            multi={false}
            searchable={false}
            separator={false}
            itemRenderer={({ item, methods }: any) => (
              <FlexRowWrapper className="custom-dropdown-item">
                <div className="option-icon">{item?.icon}</div>
                <div className="option-label"> {item?.label.toString()}</div>
              </FlexRowWrapper>
            )}
            inputRenderer={({ inputRef, props }: any) => (
              <FlexRowWrapper
                className="custom-dropdown-component"
                ref={inputRef}
              >
                <FlexRowWrapper className="label-symbol-wrapper">
                  <PolygonIcon />
                  {props.options[0].value}
                </FlexRowWrapper>

                {/* <Button
                  className="connection-status-button"
                  onClick={() => console.log('dropdown button click')}
                >
                  Disconnect
                </Button> */}
              </FlexRowWrapper>
            )}
            labelField={'label'}
            valueField={'value'}
            direction={'ltr'}
            dropdownPosition={'auto'}
            dropdownHeight={'300px'}
            dropdownGap={12}
            placeholder={'Select NFT'}
            color="#eee"
            closeOnScroll
            closeOnSelect
          />
        }
      />

      <LabelElement
        label="Recipient Address"
        element={<input type="text" disabled value={accountAddress} />}
      />
      <LabelElement
        label="Token Address"
        element={
          <input
            type="text"
            disabled
            value={tokenContractAddress ?? 'Token contract address'}
          />
        }
      />
      <LabelElement
        label="Token ID"
        element={
          <input type="text" disabled value={tokenId ?? 'Token ID here'} />
        }
      />
      <FlexColumnWrapper className="t-warning">
        <div className="warning-info">
          You will have to pay transaction fees on Polygon to redeem your NFT.
        </div>
        <FlexRowWrapper className="gas-fees-info">
          <GasIcon /> Est. Fees: 0.0239 - 0.0342 Matic
        </FlexRowWrapper>
      </FlexColumnWrapper>
      {/* <Button
        onClick={() => console.log('target next')}
        className="next-button"
      >
        Next
      </Button> */}
    </TargetWrapper>
  );
};

export default Target;
