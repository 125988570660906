export const chainIds = [1, 56, 97, 137];

export const chainNetworkInfo: {
  [key: number]: {
    host: string;
    networkName: string;
    chainId: number;
    blockExplorer: string;
    ticker: string;
    tickerName: string;
  };
} = {
  1: {
    host: 'mainnet',
    networkName: 'Ethereum Mainnet',
    chainId: 1,
    blockExplorer: 'https://etherscan.io',
    ticker: 'ETH',
    tickerName: 'ETH',
  },
  3: {
    host: 'ropsten',
    networkName: 'Ropsten Test Network',
    chainId: 3,
    blockExplorer: 'https://ropsten.etherscan.io',
    ticker: 'ETH',
    tickerName: 'ETH',
  },
  4: {
    host: 'rinkeby',
    networkName: 'Rinkeby Test Network',
    chainId: 4,
    blockExplorer: 'https://rinkeby.etherscan.io',
    ticker: 'ETH',
    tickerName: 'ETH',
  },
  5: {
    host: 'goerli',
    networkName: 'Goerli Test Network',
    chainId: 5,
    blockExplorer: 'https://goerli.etherscan.io',
    ticker: 'ETH',
    tickerName: 'ETH',
  },
  56: {
    host: 'https://bsc-dataseed.binance.org/',
    networkName: 'Binance Smart Chain Mainnet',
    chainId: 56,
    blockExplorer: 'https://bscscan.com',
    ticker: 'BNB',
    tickerName: 'BNB',
  },
  97: {
    host: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    networkName: 'Binance Smart Chain Testnet',
    chainId: 97,
    blockExplorer: 'https://testnet.bscscan.com',
    ticker: 'BNB',
    tickerName: 'BNB',
  },
  137: {
    host: 'https://polygon-rpc.com/',
    networkName: 'Polygon Mainnet',
    chainId: 137,
    blockExplorer: 'https://polygonscan.com',
    ticker: 'MATIC',
    tickerName: 'MATIC',
  },
  80001: {
    host: 'https://rpc-mumbai.maticvigil.com/',
    networkName: 'Matic Mumbai',
    chainId: 80001,
    blockExplorer: 'https://mumbai.polygonscan.com',
    ticker: 'MATIC',
    tickerName: 'MATIC',
  },
};
