import styled from 'styled-components/macro';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import FlexColumnWrapper from '../wrappers/FlexColumnWrapper';
import FlexRowWrapper from '../wrappers/FlexRowWrapper';

import { ReactComponent as LevelBadge } from 'assets/icons/level.svg';

const UpgradeLevelCardWrapper = styled(FlexColumnWrapper)`
  width: 100%;

  gap: 12px;

  background: #ffcc00;
  border-radius: 16px;
  padding: 16px;

  ${FlexRowWrapper} {
    align-items: center;
    justify-content: space-between;

    div {
      h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 112.02%;
        color: #0c0c0c;
        margin-bottom: 4px;
      }

      h5 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 112.02%;
        color: #0c0c0c;
      }
    }
  }

  .upgrade-your-level {
    width: 100%;
    height: 29px;

    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 16px;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Inter';
    font-weight: 700;
    font-size: 12px;
    line-height: 112.02%;
    color: #0c0c0c;
  }
`;

interface UpgradeLevelCardProps {
  className?: string;
}

const UpgradeLevelCard = ({
  className,
}: UpgradeLevelCardProps): JSX.Element => {
  return (
    <UpgradeLevelCardWrapper className={clsx(className)}>
      <FlexRowWrapper>
        <div>
          <h2>Get Free</h2>
          <h5>Legendary card and more</h5>
        </div>
        <LevelBadge />
      </FlexRowWrapper>
      <Link to="/" className="upgrade-your-level">
        Upgrade your Level
      </Link>
    </UpgradeLevelCardWrapper>
  );
};

export default UpgradeLevelCard;
