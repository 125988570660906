import styled from 'styled-components/macro';

import Button from 'components/common/Button';
import Spinner from 'components/Spinner';
// import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
// import LabelElement from 'components/common/LabelElement';
// import StyledSelect from 'components/common/StyledSelect';

// import { ReactComponent as EthIcon } from 'assets/eth.svg';
// import { ReactComponent as BscIcon } from 'assets/bsc.svg';
// import { ReactComponent as PolygonIcon } from 'assets/polygon.svg';

const SendNftWrapper = styled.div`
  .transfer-button {
    width: 100%;
    height: 40px;
    font-weight: 700;
    margin: 20px 0;
    font-size: 16px;
    line-height: 112.02%;
    color: #ffffff;
    background: #0072de;
    border-radius: 42px;

    &:disabled {
      background: #0072de44;
      cursor: not-allowed;
      color: grey;
    }
  }
  .t-warning {
    padding: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: rgba(255, 204, 0, 0.2);

    border-radius: 16px;
    color: #ffffff;
  }
  .custom-dropdown-component {
    justify-content: space-between;
    align-items: center;
    .connection-status-button {
      border-radius: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .label-symbol-wrapper {
      align-items: center;
      column-gap: 8px;
    }
  }
  .custom-dropdown-item {
    align-items: center;
    column-gap: 8px;
    overflow-y: scroll;
  }

  ${Spinner} {
    width: 24px;
    height: 24px;
  }

  a {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
    color: white;
  }
`;

interface SendNftProps {
  disabled?: boolean;
  loading?: boolean;
  explorerTransactionUrl?: string;
  onTransferClick?: () => void;
}

const SendNft = ({
  disabled = false,
  loading = false,
  explorerTransactionUrl,
  onTransferClick,
}: SendNftProps): JSX.Element => {
  return (
    <SendNftWrapper>
      {/* <LabelElement
        label="Transfer the NFT to the Wormhole Token Bridge."
        element={
          <StyledSelect
            options={[
              { icon: <BscIcon />, label: 'Binance Smart Chain', value: 'BSC' },
              {
                icon: <EthIcon />,
                label: 'Ethereum Smart Chain',
                value: 'ETH',
              },
              {
                icon: <PolygonIcon />,
                label: 'Polygon Smart Chain',
                value: 'Polygon',
              },
            ]}
            // dropdownPosition={'top'}
            values={[]}
            onChange={(value) => {
              console.log(value);
            }}
            multi={false}
            searchable={false}
            separator={false}
            itemRenderer={({ item, methods }: any) => (
              <FlexRowWrapper className="custom-dropdown-item">
                <div className="option-icon">{item?.icon}</div>
                <div className="option-label"> {item?.label.toString()}</div>
              </FlexRowWrapper>
            )}
            inputRenderer={({ inputRef, props }: any) => (
              <FlexRowWrapper
                className="custom-dropdown-component"
                ref={inputRef}
              >
                <FlexRowWrapper className="label-symbol-wrapper">
                  <EthIcon />
                  {props.options[1].value}
                </FlexRowWrapper>

                <Button
                  className="connection-status-button"
                  onClick={() => console.log('dropdown button click')}
                >
                  Disconnect
                </Button>
              </FlexRowWrapper>
            )}
            labelField={'label'}
            valueField={'value'}
            direction={'ltr'}
            // dropdownHeight={'300px'}
            dropdownGap={12}
            placeholder={'Select NFT'}
            color="#eee"
            closeOnScroll
            closeOnSelect
          />
        }
      /> */}
      <div className="t-warning">
        This will initiate the transfer on Polygon and wait for finalization. If
        you navigate away from this page before completing Step 4, you will have
        to perform the recovery workflow to complete the transfer.
      </div>
      <Button
        onClick={() => onTransferClick?.()}
        className="transfer-button"
        disabled={disabled}
      >
        {loading ? <Spinner /> : <></>}
        {loading ? <>&nbsp;&nbsp;Transferring ...</> : <>Transfer</>}
      </Button>
      {explorerTransactionUrl && (
        <a
          href={explorerTransactionUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on BSC Explorer
        </a>
      )}
    </SendNftWrapper>
  );
};

export default SendNft;
