import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';

export const isFundsSufficient = async (
  library: Web3Provider,
  erc20TokenAddress: string,
  accountAddress: string,
  userBidAmountInWei: string,
  erc20TokenContract?: Contract
) => {
  if (erc20TokenAddress === '0x0000000000000000000000000000000000000000') {
    const balance = await library.getBalance(accountAddress);
    if (balance.lt(userBidAmountInWei)) {
      return false;
    }
    return true;
  } else {
    const balance = await erc20TokenContract?.balanceOf(accountAddress);

    if (balance.lt(userBidAmountInWei)) {
      return false;
    }
    return true;
  }
};
