import styled from 'styled-components/macro';
import clsx from 'clsx';

import Spinner from 'components/Spinner';

const InteractionBlockingSpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 100vh;

  display: grid;
  place-items: center;

  backdrop-filter: blur(5px);
`;

interface InteractionBlockingSpinnerProps {
  className?: string;
}

const InteractionBlockingSpinner = ({
  className,
}: InteractionBlockingSpinnerProps): JSX.Element => {
  return (
    <InteractionBlockingSpinnerWrapper className={clsx(className)}>
      <Spinner />
    </InteractionBlockingSpinnerWrapper>
  );
};

export default InteractionBlockingSpinner;
