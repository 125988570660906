import { AppConfig } from 'ts/interfaces/global.interface';

const prodConfig: AppConfig = {
  apiBaseUrl: '',
  chainId: 56,
  blockchainAddresses: {
    mysteryBox: '0x4cc961440081e3044108b908AeC77BfDbfeEbc11',
    specialContractForWormhole: '0x1BCb7bE82fb7544736A01d2E5c1b89a05e3aF8b2',
  },
};

const stagingConfig: AppConfig = {
  apiBaseUrl: '',
  chainId: 56,
  blockchainAddresses: {
    mysteryBox: '0x4cc961440081e3044108b908AeC77BfDbfeEbc11',
    specialContractForWormhole: '0x1BCb7bE82fb7544736A01d2E5c1b89a05e3aF8b2',
  },
};

const localConfig: AppConfig = {
  apiBaseUrl: '',
  chainId: 56,
  blockchainAddresses: {
    mysteryBox: '0x4cc961440081e3044108b908AeC77BfDbfeEbc11',
    specialContractForWormhole: '0x1BCb7bE82fb7544736A01d2E5c1b89a05e3aF8b2',
  },
};

export default (function () {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return prodConfig;

    case 'staging':
      return stagingConfig;

    case 'local':
    default:
      return localConfig;
  }
})();
