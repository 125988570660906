import styled, { useTheme } from 'styled-components/macro';
import { Modal } from 'react-responsive-modal';
import { IoMdClose } from 'react-icons/io';

import config from 'config';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { hideIncorrectNetworkModal } from 'reducers/modalsControllerSlice';
import { ethereumChainsMapping } from 'utils/web3/ethereumChainsMapping';

import IncorrectNetwork from 'assets/images/incorrect-network.png';

const IncorrectNetworkModalContentWrapper = styled.div`
  padding: 30px 48px;

  img {
    width: 48px;
    height: 48px;
    margin-bottom: 13px;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: ${({ theme }) => theme.text[200]};
    margin-bottom: 13px;
  }

  p {
    width: 229px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: ${({ theme }) => theme.text[300]};

    strong {
      text-transform: uppercase;
      text-decoration: underline;
      text-decoration-style: wavy;
    }
  }
`;

const IncorrectNetworkModal = (): JSX.Element => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const showIncorrectNetworkModal = useAppSelector(
    (state) => state.modalsController.showIncorrectNetworkModal
  );

  return (
    <Modal
      open={showIncorrectNetworkModal}
      onClose={() => dispatch(hideIncorrectNetworkModal())}
      center
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      styles={{
        overlay: {
          backdropFilter: 'blur(5px)',
        },
        modalContainer: {
          background: 'rgba(255, 255, 255, 0.1)',
        },
        modal: {
          padding: 0,
          background: theme.background[100],
          width: '80%',
          maxWidth: '364px',
          height: 250,
          borderRadius: '5px',
        },
      }}
      closeIcon={<IoMdClose style={{ width: 32, height: 32 }} color="white" />}
    >
      <IncorrectNetworkModalContentWrapper>
        <img src={IncorrectNetwork} alt="" />
        <h3>Wrong Network Selected</h3>
        <p>
          Hey looks like you chose the wrong network. Please switch back to{' '}
          <strong>{ethereumChainsMapping[config.chainId]}</strong> network
        </p>
      </IncorrectNetworkModalContentWrapper>
    </Modal>
  );
};

export default IncorrectNetworkModal;
