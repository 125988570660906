import * as React from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { getUnixTime, format } from 'date-fns';

import {
  useGetMysteryBoxByIdQuery,
  useGetMysteryBoxesQuery,
} from 'generated/graphql';
import useConversionRateQuery from 'hooks/useConversionRateQuery';

import FlexColumnWrapper from 'components/common/wrappers/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
import EventDetailSection from 'components/common/sections/EventDetailSection';
import EventBoxData from 'components/event/EventBoxData';
import ArtistCard from 'components/common/cards/ArtistCard';
import Button from 'components/common/Button';

import mysteryBoxCover from 'assets/mystery-box-covers/large/mysterybox-cover-1.png';

// import { mysteryBoxesData } from 'data/mysteryBoxesData';

const EventDetailWrapper = styled.div`
  padding-bottom: 40px;

  .t-epic-mystery-box {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
  }
  .event-rarity-data {
    padding: 40px 20px;
    row-gap: 16px;
  }
  .filters-wrapper {
    column-gap: 8px;
    overflow-x: auto;
    margin-top: 20px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .active {
      background: #ffcc00;
    }
  }
  .rarity-events-wrapper {
    padding: 0 20px;
    .t-rarity-tiers {
      font-weight: 900;
      font-size: 24px;
      line-height: 112.02%;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .artists-wrapper {
      margin-top: 40px;
      gap: 8px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

interface EventDetailProps {}

const EventDetail = (_: EventDetailProps): JSX.Element => {
  const navigate = useNavigate();
  const { mysteryBoxId } = useParams();

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  React.useEffect(() => {
    if (!mysteryBoxId) {
      navigate(-1);
    }
  }, [mysteryBoxId, navigate]);

  const { data: conversionRateData } = useConversionRateQuery({
    coingeckoCryptoId: 'binancecoin',
    coingeckoCurrency: 'usd',
  });

  const { data } = useGetMysteryBoxByIdQuery({
    skip: !mysteryBoxId,
    variables: { id: +(mysteryBoxId ?? 0) },
  });
  const { data: mysteryBoxes } = useGetMysteryBoxesQuery({
    skip: !mysteryBoxId,
    variables: {
      where: {
        end_at: {
          _gt: format(
            new Date(getUnixTime(new Date()) * 1000),
            "yyyy-MM-dd'T'HH:mm:ss"
          ),
        },
        published: { _eq: true },
        left_items_count: { _gt: 0 },
        id: { _neq: +(mysteryBoxId ?? 0) },
      },
    },
  });

  return (
    <EventDetailWrapper>
      <EventDetailSection
        mysteryBoxId={+(mysteryBoxId ?? 0)}
        // eventImage="https://images.unsplash.com/photo-1516223725307-6f76b9ec8742?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80"
        eventImage={data?.mystery_box_by_pk?.file_url || mysteryBoxCover}
        artistName={
          data?.mystery_box_by_pk?.celebrity ||
          data?.mystery_box_by_pk?.title ||
          'Maroon 5'
        }
        eventName={
          data?.mystery_box_by_pk?.description ||
          data?.mystery_box_by_pk?.title ||
          'World tour 2022 - Mystery Box'
        }
        price={
          new BigNumber(data?.mystery_box_by_pk?.eth_price ?? 0)
            .dividedBy(1e18)
            .multipliedBy(conversionRateData?.data?.binancecoin?.usd ?? 1)
            .toFixed(2) || 600
        }
        duration={'10D : 20H : 32M'}
      />

      <FlexColumnWrapper className="event-rarity-data">
        <div className="t-epic-mystery-box">
          Epic Mystery box contains one of those items
        </div>
        <EventBoxData
          eventData={
            data?.mystery_box_by_pk?.mystery_box_items.map((item) => {
              return {
                name: item?.name,
                chance: Math.round(
                  ((item?.quantity ?? 1) /
                    (data?.mystery_box_by_pk?.mystery_box_items?.reduce(
                      (acc, item) => (acc += item?.quantity),
                      0
                    ) ?? 1)) *
                    100
                ),
                value: item?.value || 10,
              };
            }) ?? [
              { name: 'Purpose album Cover', chance: '50', value: 100 },
              { name: 'Music snippet from album', chance: '30', value: 500 },
              { name: 'Concert ticket', chance: '14', value: 1000 },
              {
                name: 'Backstage entry to purpose tour',
                chance: '5',
                value: 10_000,
              },
              { name: 'Date with Maroon 5', chance: '1', value: 50_000 },
            ]
          }
        />
      </FlexColumnWrapper>

      <FlexColumnWrapper className="rarity-events-wrapper">
        <div className="t-rarity-tiers">Rarity Tiers</div>
        <FlexRowWrapper className="filters-wrapper">
          <Button
            onClick={() => console.log('filter button click')}
            type="filter"
            className="active"
          >
            Legendary
          </Button>
          <Button
            onClick={() => console.log('filter button click')}
            type="filter"
          >
            Epic
          </Button>
          <Button
            onClick={() => console.log('filter button click')}
            type="filter"
          >
            Rare
          </Button>
          <Button
            onClick={() => console.log('filter button click')}
            type="filter"
          >
            Uncommon
          </Button>
        </FlexRowWrapper>
        <FlexRowWrapper className="artists-wrapper">
          {mysteryBoxes?.mystery_box?.map((box, i) => (
            <ArtistCard
              key={i}
              name={box?.celebrity || box?.title || 'Maroon 5'}
              onBuyNowClick={() => navigate(`/event/${box?.id}`)}
              profileImage={box?.file_url}
            />
          ))}
        </FlexRowWrapper>
      </FlexColumnWrapper>
    </EventDetailWrapper>
  );
};

export default EventDetail;
