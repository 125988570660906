import * as React from 'react';
import styled from 'styled-components/macro';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
// import { CHAIN_ID_ETHEREUM_ROPSTEN } from '@certusone/wormhole-sdk';

import useConversionRateQuery from 'hooks/useConversionRateQuery';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { torusConnector } from 'utils/web3/connectors/torusConnector';
import {
  showConnectWalletModal,
  showInsufficientFundsModal,
} from 'reducers/modalsControllerSlice';
import {
  useGetMysteryBoxByIdQuery,
  useBuyBoxMutation,
} from 'generated/graphql';
import { mysteryBoxContract } from 'contract-interactions/contracts/mysteryBoxContract';

import FlexColumnWrapper from 'components/common/wrappers/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
import Button from 'components/common/Button';
import EventDetailSection from 'components/common/sections/EventDetailSection';
import Spinner from 'components/Spinner';

import mysteryBoxCover from 'assets/mystery-box-covers/large/mysterybox-cover-1.png';
import { isFundsSufficient } from 'utils/web3/isFundsSufficient';

// import { getDefaultNativeCurrencySymbol } from 'utils/web3/wormhole/constants';

const BuyEventTicketWrapper = styled.div`
  .balance-create-wrapper {
    padding: 0 25px 40px;
    row-gap: 16px;
    margin-top: 57px;

    .balance-wrapper {
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 18px;
      justify-content: space-between;
      .t-account-balance {
        font-weight: 700;
        font-size: 14px;
        line-height: 152.02%;
        color: #ffffff;
      }
      .add-funds {
        font-weight: 500;
        font-size: 14px;
        line-height: 152.02%;
        text-decoration-line: underline;
        color: #ffcc00;
      }
    }
    .create-wallet {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
    }
  }

  ${Spinner} {
    width: 24px;
    height: 24px;
  }
`;

interface BuyEventTicketProps {}

const BuyEventTicket = (_: BuyEventTicketProps): JSX.Element => {
  const [balance, setBalance] = React.useState('0');
  const [purchasingMysteryBox, setPurchasingMysteryBox] = React.useState(false);

  const { account, active, activate, deactivate, library } = useWeb3React();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { mysteryBoxId } = useParams();

  React.useEffect(() => {
    if (!mysteryBoxId) {
      navigate(-1);
    }
  }, [mysteryBoxId, navigate]);

  const { data: conversionRateData } = useConversionRateQuery({
    coingeckoCryptoId: 'binancecoin',
    coingeckoCurrency: 'usd',
  });

  const [buyBoxMutation] = useBuyBoxMutation({ errorPolicy: 'all' });

  // TODO Fetch mystery box data.
  const { data: mysteryBoxData } = useGetMysteryBoxByIdQuery({
    skip: !mysteryBoxId,
    variables: { id: +(mysteryBoxId ?? 0) },
  });

  // TODO Fetch native token balance.
  React.useEffect(() => {
    if (account && library) {
      (async () => {
        try {
          const balance = await library.getBalance(account);
          if (balance) {
            setBalance(
              new BigNumber(balance.toString())
                .dividedBy(1e18)
                .multipliedBy(conversionRateData?.data?.binancecoin?.usd ?? 1)
                .toFixed(2)
            );
          }
        } catch (error: any) {
          console.log(error);
          toast.error(
            error?.[0]?.message || error?.message || JSON.stringify(error)
          );
        }
      })();
    }
  }, [account, library, conversionRateData?.data?.binancecoin?.usd]);

  const handleTorusConnectClick = async () => {
    try {
      if (!active) {
        setTimeout(() => {
          activate(torusConnector(), console.error);
        }, 500);
      }
    } catch (error: any) {
      console.error(error);
      deactivate();
      toast.error(
        error?.message ??
          error?.[0]?.message ??
          'Something went wrong: ' + JSON.stringify(error)
      );
    }
  };

  const handlePurchaseMysteryBox = async () => {
    try {
      setPurchasingMysteryBox(true);

      if (!active) {
        dispatch(showConnectWalletModal());
        return;
      }

      // TODO Check if enough balance available.
      const isSufficientBalance = await isFundsSufficient(
        library,
        '0x0000000000000000000000000000000000000000',
        account as string,
        new BigNumber(
          mysteryBoxData?.mystery_box_by_pk?.eth_price ?? 0
        ).toFixed()
      );

      console.log(isSufficientBalance);

      if (!isSufficientBalance) {
        dispatch(
          showInsufficientFundsModal(
            new BigNumber(mysteryBoxData?.mystery_box_by_pk?.eth_price ?? 0)
              .dividedBy(1e18)
              .multipliedBy(2)
              .toString()
          )
        );
        return;
      }

      const onChainBoxId = mysteryBoxData?.mystery_box_by_pk?.on_chain_box_id;

      const contract = mysteryBoxContract(library);

      const tx = await contract.buy(onChainBoxId, {
        gasLimit: 1000000,
        value: new BigNumber(
          mysteryBoxData?.mystery_box_by_pk?.eth_price ?? 0
        ).toFixed(),
      });
      console.log(tx);
      await tx.wait();
      const { data, errors } = await buyBoxMutation({
        variables: { trxHash: tx.hash },
      });

      if (errors) {
        throw errors;
      }

      if (data?.buy_box?.token_id) {
        console.log(data?.buy_box?.token_id);
        // refetch();
        navigate(`/user`, { replace: true });
      }
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    } finally {
      setPurchasingMysteryBox(false);
    }
  };

  return (
    <BuyEventTicketWrapper>
      <EventDetailSection
        // eventImage="https://images.unsplash.com/photo-1516223725307-6f76b9ec8742?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80"
        eventImage={
          mysteryBoxData?.mystery_box_by_pk?.file_url || mysteryBoxCover
        }
        artistName={
          mysteryBoxData?.mystery_box_by_pk?.celebrity ||
          mysteryBoxData?.mystery_box_by_pk?.title ||
          'Maroon 5'
        }
        eventName={
          mysteryBoxData?.mystery_box_by_pk?.description ||
          mysteryBoxData?.mystery_box_by_pk?.title ||
          'World tour 2022 - Mystery Box'
        }
        price={
          new BigNumber(mysteryBoxData?.mystery_box_by_pk?.eth_price ?? 0)
            .dividedBy(1e18)
            .multipliedBy(conversionRateData?.data?.binancecoin?.usd ?? 1)
            .toFixed(2) || 600
        }
        duration={'10D : 20H : 32M'}
        isCheckout={true}
        quantity={+((location?.state as { quantity: number })?.quantity ?? 1)}
      />

      <FlexColumnWrapper className="balance-create-wrapper">
        {active && (
          <FlexRowWrapper className="balance-wrapper">
            <div className="t-account-balance">
              Account Balance: ${balance}
              {/* &nbsp; */}
              {/* {getDefaultNativeCurrencySymbol(CHAIN_ID_ETHEREUM_ROPSTEN)} */}
            </div>
            <div
              onClick={() => {
                const moonPayUrl = `https://buy-sandbox.moonpay.com/?apiKey=pk_test_IyeP6QJDQaijTFackYsxKEUyz8oHkD&enabledPaymentMethods=credit_debit_card%2Csepa_bank_transfer%2Cgbp_bank_transfer&baseCurrencyCode=usd&currencyCode=usdt&walletAddress=${encodeURIComponent(
                  `${account}`
                )}&colorCode=%230364FF&externalCustomerId=${encodeURIComponent(
                  `${account}`
                )}&redirectURL=${encodeURIComponent(
                  window.location.href
                )}&showWalletAddressForm=true`;
                window.open(moonPayUrl, '_parent');
              }}
            >
              <div className="add-funds">Add Funds</div>
            </div>
          </FlexRowWrapper>
        )}
        {!active ? (
          <Button
            type="tertiary"
            onClick={handleTorusConnectClick}
            className="create-wallet"
          >
            Create Wallet
          </Button>
        ) : (
          <Button
            type="tertiary"
            onClick={handlePurchaseMysteryBox}
            className="create-wallet"
            disabled={purchasingMysteryBox}
          >
            {purchasingMysteryBox ? <Spinner /> : <></>}
            {purchasingMysteryBox ? (
              <>&nbsp;&nbsp;Purchasing ...</>
            ) : (
              <>Purchase Item</>
            )}
          </Button>
        )}
      </FlexColumnWrapper>
    </BuyEventTicketWrapper>
  );
};

export default BuyEventTicket;
