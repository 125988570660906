import { useQuery } from 'react-query';

import { fetchCryptoToCurrencyConversionRate } from 'apis/fetchCryptoToCurrencyConversionRate';

const useConversionRateQuery = ({
  coingeckoCryptoId,
  coingeckoCurrency,
}: {
  coingeckoCryptoId: string;
  coingeckoCurrency: string;
}) => {
  const conversionRateQuery = useQuery(
    'conversion-rate',
    () =>
      fetchCryptoToCurrencyConversionRate({
        coingeckoCryptoId,
        coingeckoCurrency,
      }),
    {
      refetchInterval: 10000,
    }
  );

  return conversionRateQuery;
};

export default useConversionRateQuery;
