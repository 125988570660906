import styled from 'styled-components/macro';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
import FlexColumnWrapper from 'components/common/wrappers/FlexColumnWrapper';

import exploreBG from 'assets/background/samsung-explore-bg.png';

const ExploreNowWrapper = styled(FlexColumnWrapper)`
  width: 100%;
  min-height: calc(100vh - 48px);
  padding: 24px;
  background-image: url(${exploreBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  background-position-y: 100px;

  .page-indicators {
    gap: 8px;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;

    .page-indicator {
      flex: 0 1 100%;
      max-width: 96px;
      height: 2px;
      background: #2e2d2d;
      border-radius: 2px;

      &.active {
        background: #e28d3c;
      }
    }
  }

  .page-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 45px;

    color: #ffffff;
  }

  .explore-now {
    width: 100%;
    height: 40px;

    background: #ffffff;
    border-radius: 42px;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: auto;

    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    line-height: 112.02%;

    color: #0072de;
  }
`;

interface ExploreNowProps {
  className?: string;
}

const ExploreNow = ({ className }: ExploreNowProps): JSX.Element => {
  return (
    <ExploreNowWrapper className={clsx(className)}>
      <FlexRowWrapper className="page-indicators">
        {new Array(4).fill(0).map((_, i) => (
          <div
            key={i}
            className={clsx(['page-indicator', { active: i === 0 }])}
          />
        ))}
      </FlexRowWrapper>
      <h1 className="page-title">Discover Rare Collections Of Art & NFTs.</h1>
      <Link to="/" className="explore-now">
        Explore Now
      </Link>
    </ExploreNowWrapper>
  );
};

export default ExploreNow;
