import ethers, { BigNumberish } from 'ethers';
import {
  hexToUint8Array,
  nativeToHexString,
  ChainId,
} from '@certusone/wormhole-sdk';
import { transferFromEth } from '@certusone/wormhole-sdk/lib/esm/nft_bridge/transfer';

async function transferFromEthUtil(
  nftBridgeAddress: string,
  erc721ContractAddress: string,
  token_id: BigNumberish,
  recipientAddress: string,
  chain: ChainId,
  signer: ethers.Signer
): Promise<ethers.ContractReceipt> {
  return transferFromEth(
    nftBridgeAddress,
    signer,
    erc721ContractAddress,
    token_id,
    chain,
    hexToUint8Array(nativeToHexString(recipientAddress, chain) || '')
  );
}
export { transferFromEthUtil };
