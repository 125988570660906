import * as React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { darkTheme } from 'theme';
import { GlobalStyles } from 'components/common/styles/GlobalStyles';
import Header from 'components/header/Header';

import Home from 'containers/Home';
import EventDetail from 'containers/EventDetail';
import BuyEventTicket from 'containers/BuyEventTicket';
import UserProfile from 'containers/UserProfile';
import NftDetails from 'containers/NftDetails';
import Wormhole from 'containers/Wormhole';
import ExploreNow from 'containers/ExploreNow';
import ModalsController from 'components/ModalsController';

const queryClient = new QueryClient();

const App = (): JSX.Element => {
  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/event/:mysteryBoxId" element={<EventDetail />} />
            <Route
              path="/checkout/:mysteryBoxId"
              element={<BuyEventTicket />}
            />
            <Route path="/user" element={<UserProfile />} />
            <Route path="/nft/:tokenId" element={<NftDetails />} />
            <Route path="/wormhole/:tokenId" element={<Wormhole />} />
            <Route path="/explore" element={<ExploreNow />} />
          </Routes>
        </QueryClientProvider>
        <ToastContainer />
        <ModalsController />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
