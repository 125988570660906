import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  numeric: any;
  timestamp: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type BuyBoxInput = {
  trx_hash: Scalars['String'];
};

export type BuyBoxOutput = {
  __typename?: 'BuyBoxOutput';
  token_id?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "asset" */
export type Asset = {
  __typename?: 'asset';
  /** An object relationship */
  collection: Collection;
  collection_id: Scalars['Int'];
  created_at: Scalars['timestamp'];
  data_url: Scalars['String'];
  description: Scalars['String'];
  external_link?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  mystery_box?: Maybe<Mystery_Box>;
  mystery_box_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  owner_address: Scalars['String'];
  token_id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  /** An array relationship */
  unrevealed_assets: Array<Unrevealed_Asset>;
  /** An aggregate relationship */
  unrevealed_assets_aggregate: Unrevealed_Asset_Aggregate;
};


/** columns and relationships of "asset" */
export type AssetUnrevealed_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Unrevealed_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unrevealed_Asset_Order_By>>;
  where?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};


/** columns and relationships of "asset" */
export type AssetUnrevealed_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unrevealed_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unrevealed_Asset_Order_By>>;
  where?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};

/** aggregated selection of "asset" */
export type Asset_Aggregate = {
  __typename?: 'asset_aggregate';
  aggregate?: Maybe<Asset_Aggregate_Fields>;
  nodes: Array<Asset>;
};

/** aggregate fields of "asset" */
export type Asset_Aggregate_Fields = {
  __typename?: 'asset_aggregate_fields';
  avg?: Maybe<Asset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Asset_Max_Fields>;
  min?: Maybe<Asset_Min_Fields>;
  stddev?: Maybe<Asset_Stddev_Fields>;
  stddev_pop?: Maybe<Asset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Asset_Stddev_Samp_Fields>;
  sum?: Maybe<Asset_Sum_Fields>;
  var_pop?: Maybe<Asset_Var_Pop_Fields>;
  var_samp?: Maybe<Asset_Var_Samp_Fields>;
  variance?: Maybe<Asset_Variance_Fields>;
};


/** aggregate fields of "asset" */
export type Asset_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Asset_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset" */
export type Asset_Aggregate_Order_By = {
  avg?: InputMaybe<Asset_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Asset_Max_Order_By>;
  min?: InputMaybe<Asset_Min_Order_By>;
  stddev?: InputMaybe<Asset_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Asset_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Asset_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Asset_Sum_Order_By>;
  var_pop?: InputMaybe<Asset_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Asset_Var_Samp_Order_By>;
  variance?: InputMaybe<Asset_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "asset" */
export type Asset_Arr_Rel_Insert_Input = {
  data: Array<Asset_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Asset_On_Conflict>;
};

/** aggregate avg on columns */
export type Asset_Avg_Fields = {
  __typename?: 'asset_avg_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "asset" */
export type Asset_Avg_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "asset". All fields are combined with a logical 'AND'. */
export type Asset_Bool_Exp = {
  _and?: InputMaybe<Array<Asset_Bool_Exp>>;
  _not?: InputMaybe<Asset_Bool_Exp>;
  _or?: InputMaybe<Array<Asset_Bool_Exp>>;
  collection?: InputMaybe<Collection_Bool_Exp>;
  collection_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data_url?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  external_link?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mystery_box?: InputMaybe<Mystery_Box_Bool_Exp>;
  mystery_box_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  unrevealed_assets?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};

/** unique or primary key constraints on table "asset" */
export enum Asset_Constraint {
  /** unique or primary key constraint */
  AssetPkey = 'asset_pkey',
  /** unique or primary key constraint */
  AssetTokenIdKey = 'asset_token_id_key'
}

/** input type for incrementing numeric columns in table "asset" */
export type Asset_Inc_Input = {
  collection_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  mystery_box_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "asset" */
export type Asset_Insert_Input = {
  collection?: InputMaybe<Collection_Obj_Rel_Insert_Input>;
  collection_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data_url?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  external_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mystery_box?: InputMaybe<Mystery_Box_Obj_Rel_Insert_Input>;
  mystery_box_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unrevealed_assets?: InputMaybe<Unrevealed_Asset_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Asset_Max_Fields = {
  __typename?: 'asset_max_fields';
  collection_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  data_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  external_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mystery_box_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "asset" */
export type Asset_Max_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_url?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Asset_Min_Fields = {
  __typename?: 'asset_min_fields';
  collection_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  data_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  external_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mystery_box_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "asset" */
export type Asset_Min_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_url?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "asset" */
export type Asset_Mutation_Response = {
  __typename?: 'asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Asset>;
};

/** input type for inserting object relation for remote table "asset" */
export type Asset_Obj_Rel_Insert_Input = {
  data: Asset_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Asset_On_Conflict>;
};

/** on_conflict condition type for table "asset" */
export type Asset_On_Conflict = {
  constraint: Asset_Constraint;
  update_columns?: Array<Asset_Update_Column>;
  where?: InputMaybe<Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "asset". */
export type Asset_Order_By = {
  collection?: InputMaybe<Collection_Order_By>;
  collection_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_url?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box?: InputMaybe<Mystery_Box_Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unrevealed_assets_aggregate?: InputMaybe<Unrevealed_Asset_Aggregate_Order_By>;
};

/** primary key columns input for table: asset */
export type Asset_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "asset" */
export enum Asset_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataUrl = 'data_url',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  Id = 'id',
  /** column name */
  MysteryBoxId = 'mystery_box_id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "asset" */
export type Asset_Set_Input = {
  collection_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data_url?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  external_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mystery_box_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Asset_Stddev_Fields = {
  __typename?: 'asset_stddev_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "asset" */
export type Asset_Stddev_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Asset_Stddev_Pop_Fields = {
  __typename?: 'asset_stddev_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "asset" */
export type Asset_Stddev_Pop_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Asset_Stddev_Samp_Fields = {
  __typename?: 'asset_stddev_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "asset" */
export type Asset_Stddev_Samp_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Asset_Sum_Fields = {
  __typename?: 'asset_sum_fields';
  collection_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mystery_box_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "asset" */
export type Asset_Sum_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** update columns of table "asset" */
export enum Asset_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataUrl = 'data_url',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  Id = 'id',
  /** column name */
  MysteryBoxId = 'mystery_box_id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Asset_Var_Pop_Fields = {
  __typename?: 'asset_var_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "asset" */
export type Asset_Var_Pop_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Asset_Var_Samp_Fields = {
  __typename?: 'asset_var_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "asset" */
export type Asset_Var_Samp_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Asset_Variance_Fields = {
  __typename?: 'asset_variance_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "asset" */
export type Asset_Variance_Order_By = {
  collection_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "collection" */
export type Collection = {
  __typename?: 'collection';
  /** An array relationship */
  assets: Array<Asset>;
  /** An aggregate relationship */
  assets_aggregate: Asset_Aggregate;
  banner_image_url: Scalars['String'];
  created_at: Scalars['timestamp'];
  description: Scalars['String'];
  external_url?: Maybe<Scalars['String']>;
  featured_image_url?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  logo_image_url: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};


/** columns and relationships of "collection" */
export type CollectionAssetsArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};


/** columns and relationships of "collection" */
export type CollectionAssets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};

/** aggregated selection of "collection" */
export type Collection_Aggregate = {
  __typename?: 'collection_aggregate';
  aggregate?: Maybe<Collection_Aggregate_Fields>;
  nodes: Array<Collection>;
};

/** aggregate fields of "collection" */
export type Collection_Aggregate_Fields = {
  __typename?: 'collection_aggregate_fields';
  avg?: Maybe<Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collection_Max_Fields>;
  min?: Maybe<Collection_Min_Fields>;
  stddev?: Maybe<Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Collection_Sum_Fields>;
  var_pop?: Maybe<Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Collection_Var_Samp_Fields>;
  variance?: Maybe<Collection_Variance_Fields>;
};


/** aggregate fields of "collection" */
export type Collection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Collection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Collection_Avg_Fields = {
  __typename?: 'collection_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "collection". All fields are combined with a logical 'AND'. */
export type Collection_Bool_Exp = {
  _and?: InputMaybe<Array<Collection_Bool_Exp>>;
  _not?: InputMaybe<Collection_Bool_Exp>;
  _or?: InputMaybe<Array<Collection_Bool_Exp>>;
  assets?: InputMaybe<Asset_Bool_Exp>;
  banner_image_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  external_url?: InputMaybe<String_Comparison_Exp>;
  featured_image_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo_image_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "collection" */
export enum Collection_Constraint {
  /** unique or primary key constraint */
  CollectionNameKey = 'collection_name_key',
  /** unique or primary key constraint */
  CollectionPkey = 'collection_pkey',
  /** unique or primary key constraint */
  CollectionSlugKey = 'collection_slug_key'
}

/** input type for incrementing numeric columns in table "collection" */
export type Collection_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "collection" */
export type Collection_Insert_Input = {
  assets?: InputMaybe<Asset_Arr_Rel_Insert_Input>;
  banner_image_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  external_url?: InputMaybe<Scalars['String']>;
  featured_image_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  logo_image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Collection_Max_Fields = {
  __typename?: 'collection_max_fields';
  banner_image_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  featured_image_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  logo_image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Collection_Min_Fields = {
  __typename?: 'collection_min_fields';
  banner_image_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  featured_image_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  logo_image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "collection" */
export type Collection_Mutation_Response = {
  __typename?: 'collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collection>;
};

/** input type for inserting object relation for remote table "collection" */
export type Collection_Obj_Rel_Insert_Input = {
  data: Collection_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Collection_On_Conflict>;
};

/** on_conflict condition type for table "collection" */
export type Collection_On_Conflict = {
  constraint: Collection_Constraint;
  update_columns?: Array<Collection_Update_Column>;
  where?: InputMaybe<Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "collection". */
export type Collection_Order_By = {
  assets_aggregate?: InputMaybe<Asset_Aggregate_Order_By>;
  banner_image_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_url?: InputMaybe<Order_By>;
  featured_image_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: collection */
export type Collection_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "collection" */
export enum Collection_Select_Column {
  /** column name */
  BannerImageUrl = 'banner_image_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  FeaturedImageUrl = 'featured_image_url',
  /** column name */
  Id = 'id',
  /** column name */
  LogoImageUrl = 'logo_image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "collection" */
export type Collection_Set_Input = {
  banner_image_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  external_url?: InputMaybe<Scalars['String']>;
  featured_image_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  logo_image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Collection_Stddev_Fields = {
  __typename?: 'collection_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Collection_Stddev_Pop_Fields = {
  __typename?: 'collection_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Collection_Stddev_Samp_Fields = {
  __typename?: 'collection_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Collection_Sum_Fields = {
  __typename?: 'collection_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "collection" */
export enum Collection_Update_Column {
  /** column name */
  BannerImageUrl = 'banner_image_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  FeaturedImageUrl = 'featured_image_url',
  /** column name */
  Id = 'id',
  /** column name */
  LogoImageUrl = 'logo_image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug'
}

/** aggregate var_pop on columns */
export type Collection_Var_Pop_Fields = {
  __typename?: 'collection_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Collection_Var_Samp_Fields = {
  __typename?: 'collection_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Collection_Variance_Fields = {
  __typename?: 'collection_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  buy_box?: Maybe<BuyBoxOutput>;
  /** delete data from the table: "asset" */
  delete_asset?: Maybe<Asset_Mutation_Response>;
  /** delete single row from the table: "asset" */
  delete_asset_by_pk?: Maybe<Asset>;
  /** delete data from the table: "collection" */
  delete_collection?: Maybe<Collection_Mutation_Response>;
  /** delete single row from the table: "collection" */
  delete_collection_by_pk?: Maybe<Collection>;
  /** delete data from the table: "mystery_box" */
  delete_mystery_box?: Maybe<Mystery_Box_Mutation_Response>;
  /** delete single row from the table: "mystery_box" */
  delete_mystery_box_by_pk?: Maybe<Mystery_Box>;
  /** delete data from the table: "mystery_box_item" */
  delete_mystery_box_item?: Maybe<Mystery_Box_Item_Mutation_Response>;
  /** delete single row from the table: "mystery_box_item" */
  delete_mystery_box_item_by_pk?: Maybe<Mystery_Box_Item>;
  /** delete data from the table: "payment_token" */
  delete_payment_token?: Maybe<Payment_Token_Mutation_Response>;
  /** delete single row from the table: "payment_token" */
  delete_payment_token_by_pk?: Maybe<Payment_Token>;
  /** delete data from the table: "unrevealed_asset" */
  delete_unrevealed_asset?: Maybe<Unrevealed_Asset_Mutation_Response>;
  /** delete single row from the table: "unrevealed_asset" */
  delete_unrevealed_asset_by_pk?: Maybe<Unrevealed_Asset>;
  /** insert data into the table: "asset" */
  insert_asset?: Maybe<Asset_Mutation_Response>;
  /** insert a single row into the table: "asset" */
  insert_asset_one?: Maybe<Asset>;
  /** insert data into the table: "collection" */
  insert_collection?: Maybe<Collection_Mutation_Response>;
  /** insert a single row into the table: "collection" */
  insert_collection_one?: Maybe<Collection>;
  /** insert data into the table: "mystery_box" */
  insert_mystery_box?: Maybe<Mystery_Box_Mutation_Response>;
  /** insert data into the table: "mystery_box_item" */
  insert_mystery_box_item?: Maybe<Mystery_Box_Item_Mutation_Response>;
  /** insert a single row into the table: "mystery_box_item" */
  insert_mystery_box_item_one?: Maybe<Mystery_Box_Item>;
  /** insert a single row into the table: "mystery_box" */
  insert_mystery_box_one?: Maybe<Mystery_Box>;
  /** insert data into the table: "payment_token" */
  insert_payment_token?: Maybe<Payment_Token_Mutation_Response>;
  /** insert a single row into the table: "payment_token" */
  insert_payment_token_one?: Maybe<Payment_Token>;
  /** insert data into the table: "unrevealed_asset" */
  insert_unrevealed_asset?: Maybe<Unrevealed_Asset_Mutation_Response>;
  /** insert a single row into the table: "unrevealed_asset" */
  insert_unrevealed_asset_one?: Maybe<Unrevealed_Asset>;
  /** update data of the table: "asset" */
  update_asset?: Maybe<Asset_Mutation_Response>;
  /** update single row of the table: "asset" */
  update_asset_by_pk?: Maybe<Asset>;
  /** update data of the table: "collection" */
  update_collection?: Maybe<Collection_Mutation_Response>;
  /** update single row of the table: "collection" */
  update_collection_by_pk?: Maybe<Collection>;
  /** update data of the table: "mystery_box" */
  update_mystery_box?: Maybe<Mystery_Box_Mutation_Response>;
  /** update single row of the table: "mystery_box" */
  update_mystery_box_by_pk?: Maybe<Mystery_Box>;
  /** update data of the table: "mystery_box_item" */
  update_mystery_box_item?: Maybe<Mystery_Box_Item_Mutation_Response>;
  /** update single row of the table: "mystery_box_item" */
  update_mystery_box_item_by_pk?: Maybe<Mystery_Box_Item>;
  /** update data of the table: "payment_token" */
  update_payment_token?: Maybe<Payment_Token_Mutation_Response>;
  /** update single row of the table: "payment_token" */
  update_payment_token_by_pk?: Maybe<Payment_Token>;
  /** update data of the table: "unrevealed_asset" */
  update_unrevealed_asset?: Maybe<Unrevealed_Asset_Mutation_Response>;
  /** update single row of the table: "unrevealed_asset" */
  update_unrevealed_asset_by_pk?: Maybe<Unrevealed_Asset>;
};


/** mutation root */
export type Mutation_RootBuy_BoxArgs = {
  object: BuyBoxInput;
};


/** mutation root */
export type Mutation_RootDelete_AssetArgs = {
  where: Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Asset_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CollectionArgs = {
  where: Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Mystery_BoxArgs = {
  where: Mystery_Box_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mystery_Box_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Mystery_Box_ItemArgs = {
  where: Mystery_Box_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mystery_Box_Item_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_TokenArgs = {
  where: Payment_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Token_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Unrevealed_AssetArgs = {
  where: Unrevealed_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unrevealed_Asset_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_AssetArgs = {
  objects: Array<Asset_Insert_Input>;
  on_conflict?: InputMaybe<Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asset_OneArgs = {
  object: Asset_Insert_Input;
  on_conflict?: InputMaybe<Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CollectionArgs = {
  objects: Array<Collection_Insert_Input>;
  on_conflict?: InputMaybe<Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_OneArgs = {
  object: Collection_Insert_Input;
  on_conflict?: InputMaybe<Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mystery_BoxArgs = {
  objects: Array<Mystery_Box_Insert_Input>;
  on_conflict?: InputMaybe<Mystery_Box_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mystery_Box_ItemArgs = {
  objects: Array<Mystery_Box_Item_Insert_Input>;
  on_conflict?: InputMaybe<Mystery_Box_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mystery_Box_Item_OneArgs = {
  object: Mystery_Box_Item_Insert_Input;
  on_conflict?: InputMaybe<Mystery_Box_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mystery_Box_OneArgs = {
  object: Mystery_Box_Insert_Input;
  on_conflict?: InputMaybe<Mystery_Box_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_TokenArgs = {
  objects: Array<Payment_Token_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Token_OneArgs = {
  object: Payment_Token_Insert_Input;
  on_conflict?: InputMaybe<Payment_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unrevealed_AssetArgs = {
  objects: Array<Unrevealed_Asset_Insert_Input>;
  on_conflict?: InputMaybe<Unrevealed_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unrevealed_Asset_OneArgs = {
  object: Unrevealed_Asset_Insert_Input;
  on_conflict?: InputMaybe<Unrevealed_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AssetArgs = {
  _inc?: InputMaybe<Asset_Inc_Input>;
  _set?: InputMaybe<Asset_Set_Input>;
  where: Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Asset_By_PkArgs = {
  _inc?: InputMaybe<Asset_Inc_Input>;
  _set?: InputMaybe<Asset_Set_Input>;
  pk_columns: Asset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CollectionArgs = {
  _inc?: InputMaybe<Collection_Inc_Input>;
  _set?: InputMaybe<Collection_Set_Input>;
  where: Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_By_PkArgs = {
  _inc?: InputMaybe<Collection_Inc_Input>;
  _set?: InputMaybe<Collection_Set_Input>;
  pk_columns: Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mystery_BoxArgs = {
  _inc?: InputMaybe<Mystery_Box_Inc_Input>;
  _set?: InputMaybe<Mystery_Box_Set_Input>;
  where: Mystery_Box_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mystery_Box_By_PkArgs = {
  _inc?: InputMaybe<Mystery_Box_Inc_Input>;
  _set?: InputMaybe<Mystery_Box_Set_Input>;
  pk_columns: Mystery_Box_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mystery_Box_ItemArgs = {
  _inc?: InputMaybe<Mystery_Box_Item_Inc_Input>;
  _set?: InputMaybe<Mystery_Box_Item_Set_Input>;
  where: Mystery_Box_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mystery_Box_Item_By_PkArgs = {
  _inc?: InputMaybe<Mystery_Box_Item_Inc_Input>;
  _set?: InputMaybe<Mystery_Box_Item_Set_Input>;
  pk_columns: Mystery_Box_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_TokenArgs = {
  _inc?: InputMaybe<Payment_Token_Inc_Input>;
  _set?: InputMaybe<Payment_Token_Set_Input>;
  where: Payment_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Token_By_PkArgs = {
  _inc?: InputMaybe<Payment_Token_Inc_Input>;
  _set?: InputMaybe<Payment_Token_Set_Input>;
  pk_columns: Payment_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unrevealed_AssetArgs = {
  _inc?: InputMaybe<Unrevealed_Asset_Inc_Input>;
  _set?: InputMaybe<Unrevealed_Asset_Set_Input>;
  where: Unrevealed_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unrevealed_Asset_By_PkArgs = {
  _inc?: InputMaybe<Unrevealed_Asset_Inc_Input>;
  _set?: InputMaybe<Unrevealed_Asset_Set_Input>;
  pk_columns: Unrevealed_Asset_Pk_Columns_Input;
};

/** columns and relationships of "mystery_box" */
export type Mystery_Box = {
  __typename?: 'mystery_box';
  /** An array relationship */
  assets: Array<Asset>;
  /** An aggregate relationship */
  assets_aggregate: Asset_Aggregate;
  celebrity: Scalars['String'];
  description: Scalars['String'];
  end_at: Scalars['timestamp'];
  eth_price: Scalars['String'];
  file_url: Scalars['String'];
  id: Scalars['Int'];
  left_items_count: Scalars['Int'];
  /** An array relationship */
  mystery_box_items: Array<Mystery_Box_Item>;
  /** An aggregate relationship */
  mystery_box_items_aggregate: Mystery_Box_Item_Aggregate;
  on_chain_box_id: Scalars['Int'];
  published: Scalars['Boolean'];
  rarity: Scalars['String'];
  revealed: Scalars['Boolean'];
  revealed_at?: Maybe<Scalars['timestamp']>;
  start_at: Scalars['timestamp'];
  tag: Scalars['String'];
  title: Scalars['String'];
  total_items_count: Scalars['Int'];
};


/** columns and relationships of "mystery_box" */
export type Mystery_BoxAssetsArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};


/** columns and relationships of "mystery_box" */
export type Mystery_BoxAssets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};


/** columns and relationships of "mystery_box" */
export type Mystery_BoxMystery_Box_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Item_Order_By>>;
  where?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
};


/** columns and relationships of "mystery_box" */
export type Mystery_BoxMystery_Box_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Item_Order_By>>;
  where?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
};

/** aggregated selection of "mystery_box" */
export type Mystery_Box_Aggregate = {
  __typename?: 'mystery_box_aggregate';
  aggregate?: Maybe<Mystery_Box_Aggregate_Fields>;
  nodes: Array<Mystery_Box>;
};

/** aggregate fields of "mystery_box" */
export type Mystery_Box_Aggregate_Fields = {
  __typename?: 'mystery_box_aggregate_fields';
  avg?: Maybe<Mystery_Box_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mystery_Box_Max_Fields>;
  min?: Maybe<Mystery_Box_Min_Fields>;
  stddev?: Maybe<Mystery_Box_Stddev_Fields>;
  stddev_pop?: Maybe<Mystery_Box_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mystery_Box_Stddev_Samp_Fields>;
  sum?: Maybe<Mystery_Box_Sum_Fields>;
  var_pop?: Maybe<Mystery_Box_Var_Pop_Fields>;
  var_samp?: Maybe<Mystery_Box_Var_Samp_Fields>;
  variance?: Maybe<Mystery_Box_Variance_Fields>;
};


/** aggregate fields of "mystery_box" */
export type Mystery_Box_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mystery_Box_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mystery_Box_Avg_Fields = {
  __typename?: 'mystery_box_avg_fields';
  id?: Maybe<Scalars['Float']>;
  left_items_count?: Maybe<Scalars['Float']>;
  on_chain_box_id?: Maybe<Scalars['Float']>;
  total_items_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mystery_box". All fields are combined with a logical 'AND'. */
export type Mystery_Box_Bool_Exp = {
  _and?: InputMaybe<Array<Mystery_Box_Bool_Exp>>;
  _not?: InputMaybe<Mystery_Box_Bool_Exp>;
  _or?: InputMaybe<Array<Mystery_Box_Bool_Exp>>;
  assets?: InputMaybe<Asset_Bool_Exp>;
  celebrity?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  end_at?: InputMaybe<Timestamp_Comparison_Exp>;
  eth_price?: InputMaybe<String_Comparison_Exp>;
  file_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  left_items_count?: InputMaybe<Int_Comparison_Exp>;
  mystery_box_items?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
  on_chain_box_id?: InputMaybe<Int_Comparison_Exp>;
  published?: InputMaybe<Boolean_Comparison_Exp>;
  rarity?: InputMaybe<String_Comparison_Exp>;
  revealed?: InputMaybe<Boolean_Comparison_Exp>;
  revealed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  start_at?: InputMaybe<Timestamp_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  total_items_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "mystery_box" */
export enum Mystery_Box_Constraint {
  /** unique or primary key constraint */
  MysteryBoxPkey = 'mystery_box_pkey'
}

/** input type for incrementing numeric columns in table "mystery_box" */
export type Mystery_Box_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  left_items_count?: InputMaybe<Scalars['Int']>;
  on_chain_box_id?: InputMaybe<Scalars['Int']>;
  total_items_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mystery_box" */
export type Mystery_Box_Insert_Input = {
  assets?: InputMaybe<Asset_Arr_Rel_Insert_Input>;
  celebrity?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['timestamp']>;
  eth_price?: InputMaybe<Scalars['String']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  left_items_count?: InputMaybe<Scalars['Int']>;
  mystery_box_items?: InputMaybe<Mystery_Box_Item_Arr_Rel_Insert_Input>;
  on_chain_box_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  rarity?: InputMaybe<Scalars['String']>;
  revealed?: InputMaybe<Scalars['Boolean']>;
  revealed_at?: InputMaybe<Scalars['timestamp']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
  tag?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_items_count?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "mystery_box_item" */
export type Mystery_Box_Item = {
  __typename?: 'mystery_box_item';
  description: Scalars['String'];
  external_link?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  mystery_box: Mystery_Box;
  mystery_box_id: Scalars['Int'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "mystery_box_item" */
export type Mystery_Box_Item_Aggregate = {
  __typename?: 'mystery_box_item_aggregate';
  aggregate?: Maybe<Mystery_Box_Item_Aggregate_Fields>;
  nodes: Array<Mystery_Box_Item>;
};

/** aggregate fields of "mystery_box_item" */
export type Mystery_Box_Item_Aggregate_Fields = {
  __typename?: 'mystery_box_item_aggregate_fields';
  avg?: Maybe<Mystery_Box_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mystery_Box_Item_Max_Fields>;
  min?: Maybe<Mystery_Box_Item_Min_Fields>;
  stddev?: Maybe<Mystery_Box_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Mystery_Box_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mystery_Box_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Mystery_Box_Item_Sum_Fields>;
  var_pop?: Maybe<Mystery_Box_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Mystery_Box_Item_Var_Samp_Fields>;
  variance?: Maybe<Mystery_Box_Item_Variance_Fields>;
};


/** aggregate fields of "mystery_box_item" */
export type Mystery_Box_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mystery_Box_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mystery_box_item" */
export type Mystery_Box_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Mystery_Box_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mystery_Box_Item_Max_Order_By>;
  min?: InputMaybe<Mystery_Box_Item_Min_Order_By>;
  stddev?: InputMaybe<Mystery_Box_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mystery_Box_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mystery_Box_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mystery_Box_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Mystery_Box_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mystery_Box_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Mystery_Box_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mystery_box_item" */
export type Mystery_Box_Item_Arr_Rel_Insert_Input = {
  data: Array<Mystery_Box_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mystery_Box_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Mystery_Box_Item_Avg_Fields = {
  __typename?: 'mystery_box_item_avg_fields';
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mystery_box_item". All fields are combined with a logical 'AND'. */
export type Mystery_Box_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Mystery_Box_Item_Bool_Exp>>;
  _not?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Mystery_Box_Item_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  external_link?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mystery_box?: InputMaybe<Mystery_Box_Bool_Exp>;
  mystery_box_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mystery_box_item" */
export enum Mystery_Box_Item_Constraint {
  /** unique or primary key constraint */
  MysteryBoxItemPkey = 'mystery_box_item_pkey'
}

/** input type for incrementing numeric columns in table "mystery_box_item" */
export type Mystery_Box_Item_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  mystery_box_id?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mystery_box_item" */
export type Mystery_Box_Item_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  external_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mystery_box?: InputMaybe<Mystery_Box_Obj_Rel_Insert_Input>;
  mystery_box_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mystery_Box_Item_Max_Fields = {
  __typename?: 'mystery_box_item_max_fields';
  description?: Maybe<Scalars['String']>;
  external_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mystery_box_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mystery_Box_Item_Min_Fields = {
  __typename?: 'mystery_box_item_min_fields';
  description?: Maybe<Scalars['String']>;
  external_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mystery_box_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mystery_box_item" */
export type Mystery_Box_Item_Mutation_Response = {
  __typename?: 'mystery_box_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mystery_Box_Item>;
};

/** on_conflict condition type for table "mystery_box_item" */
export type Mystery_Box_Item_On_Conflict = {
  constraint: Mystery_Box_Item_Constraint;
  update_columns?: Array<Mystery_Box_Item_Update_Column>;
  where?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "mystery_box_item". */
export type Mystery_Box_Item_Order_By = {
  description?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mystery_box?: InputMaybe<Mystery_Box_Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mystery_box_item */
export type Mystery_Box_Item_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "mystery_box_item" */
export enum Mystery_Box_Item_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  Id = 'id',
  /** column name */
  MysteryBoxId = 'mystery_box_id',
  /** column name */
  Name = 'name',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "mystery_box_item" */
export type Mystery_Box_Item_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  external_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mystery_box_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Mystery_Box_Item_Stddev_Fields = {
  __typename?: 'mystery_box_item_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mystery_Box_Item_Stddev_Pop_Fields = {
  __typename?: 'mystery_box_item_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mystery_Box_Item_Stddev_Samp_Fields = {
  __typename?: 'mystery_box_item_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Mystery_Box_Item_Sum_Fields = {
  __typename?: 'mystery_box_item_sum_fields';
  id?: Maybe<Scalars['Int']>;
  mystery_box_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "mystery_box_item" */
export enum Mystery_Box_Item_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  Id = 'id',
  /** column name */
  MysteryBoxId = 'mystery_box_id',
  /** column name */
  Name = 'name',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Mystery_Box_Item_Var_Pop_Fields = {
  __typename?: 'mystery_box_item_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mystery_Box_Item_Var_Samp_Fields = {
  __typename?: 'mystery_box_item_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mystery_Box_Item_Variance_Fields = {
  __typename?: 'mystery_box_item_variance_fields';
  id?: Maybe<Scalars['Float']>;
  mystery_box_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mystery_box_item" */
export type Mystery_Box_Item_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  mystery_box_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Mystery_Box_Max_Fields = {
  __typename?: 'mystery_box_max_fields';
  celebrity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['timestamp']>;
  eth_price?: Maybe<Scalars['String']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  left_items_count?: Maybe<Scalars['Int']>;
  on_chain_box_id?: Maybe<Scalars['Int']>;
  rarity?: Maybe<Scalars['String']>;
  revealed_at?: Maybe<Scalars['timestamp']>;
  start_at?: Maybe<Scalars['timestamp']>;
  tag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_items_count?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Mystery_Box_Min_Fields = {
  __typename?: 'mystery_box_min_fields';
  celebrity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['timestamp']>;
  eth_price?: Maybe<Scalars['String']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  left_items_count?: Maybe<Scalars['Int']>;
  on_chain_box_id?: Maybe<Scalars['Int']>;
  rarity?: Maybe<Scalars['String']>;
  revealed_at?: Maybe<Scalars['timestamp']>;
  start_at?: Maybe<Scalars['timestamp']>;
  tag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_items_count?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "mystery_box" */
export type Mystery_Box_Mutation_Response = {
  __typename?: 'mystery_box_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mystery_Box>;
};

/** input type for inserting object relation for remote table "mystery_box" */
export type Mystery_Box_Obj_Rel_Insert_Input = {
  data: Mystery_Box_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mystery_Box_On_Conflict>;
};

/** on_conflict condition type for table "mystery_box" */
export type Mystery_Box_On_Conflict = {
  constraint: Mystery_Box_Constraint;
  update_columns?: Array<Mystery_Box_Update_Column>;
  where?: InputMaybe<Mystery_Box_Bool_Exp>;
};

/** Ordering options when selecting data from "mystery_box". */
export type Mystery_Box_Order_By = {
  assets_aggregate?: InputMaybe<Asset_Aggregate_Order_By>;
  celebrity?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_at?: InputMaybe<Order_By>;
  eth_price?: InputMaybe<Order_By>;
  file_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  left_items_count?: InputMaybe<Order_By>;
  mystery_box_items_aggregate?: InputMaybe<Mystery_Box_Item_Aggregate_Order_By>;
  on_chain_box_id?: InputMaybe<Order_By>;
  published?: InputMaybe<Order_By>;
  rarity?: InputMaybe<Order_By>;
  revealed?: InputMaybe<Order_By>;
  revealed_at?: InputMaybe<Order_By>;
  start_at?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  total_items_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mystery_box */
export type Mystery_Box_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "mystery_box" */
export enum Mystery_Box_Select_Column {
  /** column name */
  Celebrity = 'celebrity',
  /** column name */
  Description = 'description',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  EthPrice = 'eth_price',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  LeftItemsCount = 'left_items_count',
  /** column name */
  OnChainBoxId = 'on_chain_box_id',
  /** column name */
  Published = 'published',
  /** column name */
  Rarity = 'rarity',
  /** column name */
  Revealed = 'revealed',
  /** column name */
  RevealedAt = 'revealed_at',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Tag = 'tag',
  /** column name */
  Title = 'title',
  /** column name */
  TotalItemsCount = 'total_items_count'
}

/** input type for updating data in table "mystery_box" */
export type Mystery_Box_Set_Input = {
  celebrity?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end_at?: InputMaybe<Scalars['timestamp']>;
  eth_price?: InputMaybe<Scalars['String']>;
  file_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  left_items_count?: InputMaybe<Scalars['Int']>;
  on_chain_box_id?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  rarity?: InputMaybe<Scalars['String']>;
  revealed?: InputMaybe<Scalars['Boolean']>;
  revealed_at?: InputMaybe<Scalars['timestamp']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
  tag?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  total_items_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Mystery_Box_Stddev_Fields = {
  __typename?: 'mystery_box_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  left_items_count?: Maybe<Scalars['Float']>;
  on_chain_box_id?: Maybe<Scalars['Float']>;
  total_items_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mystery_Box_Stddev_Pop_Fields = {
  __typename?: 'mystery_box_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  left_items_count?: Maybe<Scalars['Float']>;
  on_chain_box_id?: Maybe<Scalars['Float']>;
  total_items_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mystery_Box_Stddev_Samp_Fields = {
  __typename?: 'mystery_box_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  left_items_count?: Maybe<Scalars['Float']>;
  on_chain_box_id?: Maybe<Scalars['Float']>;
  total_items_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Mystery_Box_Sum_Fields = {
  __typename?: 'mystery_box_sum_fields';
  id?: Maybe<Scalars['Int']>;
  left_items_count?: Maybe<Scalars['Int']>;
  on_chain_box_id?: Maybe<Scalars['Int']>;
  total_items_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "mystery_box" */
export enum Mystery_Box_Update_Column {
  /** column name */
  Celebrity = 'celebrity',
  /** column name */
  Description = 'description',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  EthPrice = 'eth_price',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  LeftItemsCount = 'left_items_count',
  /** column name */
  OnChainBoxId = 'on_chain_box_id',
  /** column name */
  Published = 'published',
  /** column name */
  Rarity = 'rarity',
  /** column name */
  Revealed = 'revealed',
  /** column name */
  RevealedAt = 'revealed_at',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Tag = 'tag',
  /** column name */
  Title = 'title',
  /** column name */
  TotalItemsCount = 'total_items_count'
}

/** aggregate var_pop on columns */
export type Mystery_Box_Var_Pop_Fields = {
  __typename?: 'mystery_box_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  left_items_count?: Maybe<Scalars['Float']>;
  on_chain_box_id?: Maybe<Scalars['Float']>;
  total_items_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mystery_Box_Var_Samp_Fields = {
  __typename?: 'mystery_box_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  left_items_count?: Maybe<Scalars['Float']>;
  on_chain_box_id?: Maybe<Scalars['Float']>;
  total_items_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mystery_Box_Variance_Fields = {
  __typename?: 'mystery_box_variance_fields';
  id?: Maybe<Scalars['Float']>;
  left_items_count?: Maybe<Scalars['Float']>;
  on_chain_box_id?: Maybe<Scalars['Float']>;
  total_items_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment_token" */
export type Payment_Token = {
  __typename?: 'payment_token';
  address: Scalars['String'];
  decimals: Scalars['String'];
  display_name: Scalars['String'];
  eth_price: Scalars['numeric'];
  image_url: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  usd_price: Scalars['numeric'];
};

/** aggregated selection of "payment_token" */
export type Payment_Token_Aggregate = {
  __typename?: 'payment_token_aggregate';
  aggregate?: Maybe<Payment_Token_Aggregate_Fields>;
  nodes: Array<Payment_Token>;
};

/** aggregate fields of "payment_token" */
export type Payment_Token_Aggregate_Fields = {
  __typename?: 'payment_token_aggregate_fields';
  avg?: Maybe<Payment_Token_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Token_Max_Fields>;
  min?: Maybe<Payment_Token_Min_Fields>;
  stddev?: Maybe<Payment_Token_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Token_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Token_Sum_Fields>;
  var_pop?: Maybe<Payment_Token_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Token_Var_Samp_Fields>;
  variance?: Maybe<Payment_Token_Variance_Fields>;
};


/** aggregate fields of "payment_token" */
export type Payment_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Token_Avg_Fields = {
  __typename?: 'payment_token_avg_fields';
  eth_price?: Maybe<Scalars['Float']>;
  usd_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_token". All fields are combined with a logical 'AND'. */
export type Payment_Token_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Token_Bool_Exp>>;
  _not?: InputMaybe<Payment_Token_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Token_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  decimals?: InputMaybe<String_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  eth_price?: InputMaybe<Numeric_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  usd_price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_token" */
export enum Payment_Token_Constraint {
  /** unique or primary key constraint */
  PaymentTokenAddressKey = 'payment_token_address_key',
  /** unique or primary key constraint */
  PaymentTokenPkey = 'payment_token_pkey'
}

/** input type for incrementing numeric columns in table "payment_token" */
export type Payment_Token_Inc_Input = {
  eth_price?: InputMaybe<Scalars['numeric']>;
  usd_price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "payment_token" */
export type Payment_Token_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  eth_price?: InputMaybe<Scalars['numeric']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  usd_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Payment_Token_Max_Fields = {
  __typename?: 'payment_token_max_fields';
  address?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  eth_price?: Maybe<Scalars['numeric']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  usd_price?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Payment_Token_Min_Fields = {
  __typename?: 'payment_token_min_fields';
  address?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  eth_price?: Maybe<Scalars['numeric']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  usd_price?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "payment_token" */
export type Payment_Token_Mutation_Response = {
  __typename?: 'payment_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Token>;
};

/** on_conflict condition type for table "payment_token" */
export type Payment_Token_On_Conflict = {
  constraint: Payment_Token_Constraint;
  update_columns?: Array<Payment_Token_Update_Column>;
  where?: InputMaybe<Payment_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_token". */
export type Payment_Token_Order_By = {
  address?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  eth_price?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  usd_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_token */
export type Payment_Token_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "payment_token" */
export enum Payment_Token_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EthPrice = 'eth_price',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UsdPrice = 'usd_price'
}

/** input type for updating data in table "payment_token" */
export type Payment_Token_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  eth_price?: InputMaybe<Scalars['numeric']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  usd_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Payment_Token_Stddev_Fields = {
  __typename?: 'payment_token_stddev_fields';
  eth_price?: Maybe<Scalars['Float']>;
  usd_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Token_Stddev_Pop_Fields = {
  __typename?: 'payment_token_stddev_pop_fields';
  eth_price?: Maybe<Scalars['Float']>;
  usd_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Token_Stddev_Samp_Fields = {
  __typename?: 'payment_token_stddev_samp_fields';
  eth_price?: Maybe<Scalars['Float']>;
  usd_price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Payment_Token_Sum_Fields = {
  __typename?: 'payment_token_sum_fields';
  eth_price?: Maybe<Scalars['numeric']>;
  usd_price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "payment_token" */
export enum Payment_Token_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  EthPrice = 'eth_price',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UsdPrice = 'usd_price'
}

/** aggregate var_pop on columns */
export type Payment_Token_Var_Pop_Fields = {
  __typename?: 'payment_token_var_pop_fields';
  eth_price?: Maybe<Scalars['Float']>;
  usd_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Token_Var_Samp_Fields = {
  __typename?: 'payment_token_var_samp_fields';
  eth_price?: Maybe<Scalars['Float']>;
  usd_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Token_Variance_Fields = {
  __typename?: 'payment_token_variance_fields';
  eth_price?: Maybe<Scalars['Float']>;
  usd_price?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "asset" */
  asset: Array<Asset>;
  /** fetch aggregated fields from the table: "asset" */
  asset_aggregate: Asset_Aggregate;
  /** fetch data from the table: "asset" using primary key columns */
  asset_by_pk?: Maybe<Asset>;
  /** fetch data from the table: "collection" */
  collection: Array<Collection>;
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate;
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>;
  /** fetch data from the table: "mystery_box" */
  mystery_box: Array<Mystery_Box>;
  /** fetch aggregated fields from the table: "mystery_box" */
  mystery_box_aggregate: Mystery_Box_Aggregate;
  /** fetch data from the table: "mystery_box" using primary key columns */
  mystery_box_by_pk?: Maybe<Mystery_Box>;
  /** fetch data from the table: "mystery_box_item" */
  mystery_box_item: Array<Mystery_Box_Item>;
  /** fetch aggregated fields from the table: "mystery_box_item" */
  mystery_box_item_aggregate: Mystery_Box_Item_Aggregate;
  /** fetch data from the table: "mystery_box_item" using primary key columns */
  mystery_box_item_by_pk?: Maybe<Mystery_Box_Item>;
  /** fetch data from the table: "payment_token" */
  payment_token: Array<Payment_Token>;
  /** fetch aggregated fields from the table: "payment_token" */
  payment_token_aggregate: Payment_Token_Aggregate;
  /** fetch data from the table: "payment_token" using primary key columns */
  payment_token_by_pk?: Maybe<Payment_Token>;
  /** fetch data from the table: "unrevealed_asset" */
  unrevealed_asset: Array<Unrevealed_Asset>;
  /** fetch aggregated fields from the table: "unrevealed_asset" */
  unrevealed_asset_aggregate: Unrevealed_Asset_Aggregate;
  /** fetch data from the table: "unrevealed_asset" using primary key columns */
  unrevealed_asset_by_pk?: Maybe<Unrevealed_Asset>;
};


export type Query_RootAssetArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};


export type Query_RootAsset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};


export type Query_RootAsset_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCollectionArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Query_RootCollection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Query_RootCollection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMystery_BoxArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Order_By>>;
  where?: InputMaybe<Mystery_Box_Bool_Exp>;
};


export type Query_RootMystery_Box_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Order_By>>;
  where?: InputMaybe<Mystery_Box_Bool_Exp>;
};


export type Query_RootMystery_Box_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMystery_Box_ItemArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Item_Order_By>>;
  where?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
};


export type Query_RootMystery_Box_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Item_Order_By>>;
  where?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
};


export type Query_RootMystery_Box_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPayment_TokenArgs = {
  distinct_on?: InputMaybe<Array<Payment_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Token_Order_By>>;
  where?: InputMaybe<Payment_Token_Bool_Exp>;
};


export type Query_RootPayment_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Token_Order_By>>;
  where?: InputMaybe<Payment_Token_Bool_Exp>;
};


export type Query_RootPayment_Token_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootUnrevealed_AssetArgs = {
  distinct_on?: InputMaybe<Array<Unrevealed_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unrevealed_Asset_Order_By>>;
  where?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};


export type Query_RootUnrevealed_Asset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unrevealed_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unrevealed_Asset_Order_By>>;
  where?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};


export type Query_RootUnrevealed_Asset_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "asset" */
  asset: Array<Asset>;
  /** fetch aggregated fields from the table: "asset" */
  asset_aggregate: Asset_Aggregate;
  /** fetch data from the table: "asset" using primary key columns */
  asset_by_pk?: Maybe<Asset>;
  /** fetch data from the table: "collection" */
  collection: Array<Collection>;
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate;
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>;
  /** fetch data from the table: "mystery_box" */
  mystery_box: Array<Mystery_Box>;
  /** fetch aggregated fields from the table: "mystery_box" */
  mystery_box_aggregate: Mystery_Box_Aggregate;
  /** fetch data from the table: "mystery_box" using primary key columns */
  mystery_box_by_pk?: Maybe<Mystery_Box>;
  /** fetch data from the table: "mystery_box_item" */
  mystery_box_item: Array<Mystery_Box_Item>;
  /** fetch aggregated fields from the table: "mystery_box_item" */
  mystery_box_item_aggregate: Mystery_Box_Item_Aggregate;
  /** fetch data from the table: "mystery_box_item" using primary key columns */
  mystery_box_item_by_pk?: Maybe<Mystery_Box_Item>;
  /** fetch data from the table: "payment_token" */
  payment_token: Array<Payment_Token>;
  /** fetch aggregated fields from the table: "payment_token" */
  payment_token_aggregate: Payment_Token_Aggregate;
  /** fetch data from the table: "payment_token" using primary key columns */
  payment_token_by_pk?: Maybe<Payment_Token>;
  /** fetch data from the table: "unrevealed_asset" */
  unrevealed_asset: Array<Unrevealed_Asset>;
  /** fetch aggregated fields from the table: "unrevealed_asset" */
  unrevealed_asset_aggregate: Unrevealed_Asset_Aggregate;
  /** fetch data from the table: "unrevealed_asset" using primary key columns */
  unrevealed_asset_by_pk?: Maybe<Unrevealed_Asset>;
};


export type Subscription_RootAssetArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};


export type Subscription_RootAsset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Asset_Order_By>>;
  where?: InputMaybe<Asset_Bool_Exp>;
};


export type Subscription_RootAsset_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCollectionArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Subscription_RootCollection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Collection_Order_By>>;
  where?: InputMaybe<Collection_Bool_Exp>;
};


export type Subscription_RootCollection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMystery_BoxArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Order_By>>;
  where?: InputMaybe<Mystery_Box_Bool_Exp>;
};


export type Subscription_RootMystery_Box_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Order_By>>;
  where?: InputMaybe<Mystery_Box_Bool_Exp>;
};


export type Subscription_RootMystery_Box_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMystery_Box_ItemArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Item_Order_By>>;
  where?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
};


export type Subscription_RootMystery_Box_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mystery_Box_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mystery_Box_Item_Order_By>>;
  where?: InputMaybe<Mystery_Box_Item_Bool_Exp>;
};


export type Subscription_RootMystery_Box_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPayment_TokenArgs = {
  distinct_on?: InputMaybe<Array<Payment_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Token_Order_By>>;
  where?: InputMaybe<Payment_Token_Bool_Exp>;
};


export type Subscription_RootPayment_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Token_Order_By>>;
  where?: InputMaybe<Payment_Token_Bool_Exp>;
};


export type Subscription_RootPayment_Token_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootUnrevealed_AssetArgs = {
  distinct_on?: InputMaybe<Array<Unrevealed_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unrevealed_Asset_Order_By>>;
  where?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};


export type Subscription_RootUnrevealed_Asset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unrevealed_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unrevealed_Asset_Order_By>>;
  where?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};


export type Subscription_RootUnrevealed_Asset_By_PkArgs = {
  id: Scalars['String'];
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** columns and relationships of "unrevealed_asset" */
export type Unrevealed_Asset = {
  __typename?: 'unrevealed_asset';
  /** An object relationship */
  asset: Asset;
  assetId: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
  token_id: Scalars['String'];
};

/** aggregated selection of "unrevealed_asset" */
export type Unrevealed_Asset_Aggregate = {
  __typename?: 'unrevealed_asset_aggregate';
  aggregate?: Maybe<Unrevealed_Asset_Aggregate_Fields>;
  nodes: Array<Unrevealed_Asset>;
};

/** aggregate fields of "unrevealed_asset" */
export type Unrevealed_Asset_Aggregate_Fields = {
  __typename?: 'unrevealed_asset_aggregate_fields';
  avg?: Maybe<Unrevealed_Asset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Unrevealed_Asset_Max_Fields>;
  min?: Maybe<Unrevealed_Asset_Min_Fields>;
  stddev?: Maybe<Unrevealed_Asset_Stddev_Fields>;
  stddev_pop?: Maybe<Unrevealed_Asset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unrevealed_Asset_Stddev_Samp_Fields>;
  sum?: Maybe<Unrevealed_Asset_Sum_Fields>;
  var_pop?: Maybe<Unrevealed_Asset_Var_Pop_Fields>;
  var_samp?: Maybe<Unrevealed_Asset_Var_Samp_Fields>;
  variance?: Maybe<Unrevealed_Asset_Variance_Fields>;
};


/** aggregate fields of "unrevealed_asset" */
export type Unrevealed_Asset_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unrevealed_Asset_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "unrevealed_asset" */
export type Unrevealed_Asset_Aggregate_Order_By = {
  avg?: InputMaybe<Unrevealed_Asset_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Unrevealed_Asset_Max_Order_By>;
  min?: InputMaybe<Unrevealed_Asset_Min_Order_By>;
  stddev?: InputMaybe<Unrevealed_Asset_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Unrevealed_Asset_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Unrevealed_Asset_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Unrevealed_Asset_Sum_Order_By>;
  var_pop?: InputMaybe<Unrevealed_Asset_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Unrevealed_Asset_Var_Samp_Order_By>;
  variance?: InputMaybe<Unrevealed_Asset_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "unrevealed_asset" */
export type Unrevealed_Asset_Arr_Rel_Insert_Input = {
  data: Array<Unrevealed_Asset_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Unrevealed_Asset_On_Conflict>;
};

/** aggregate avg on columns */
export type Unrevealed_Asset_Avg_Fields = {
  __typename?: 'unrevealed_asset_avg_fields';
  assetId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Avg_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unrevealed_asset". All fields are combined with a logical 'AND'. */
export type Unrevealed_Asset_Bool_Exp = {
  _and?: InputMaybe<Array<Unrevealed_Asset_Bool_Exp>>;
  _not?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
  _or?: InputMaybe<Array<Unrevealed_Asset_Bool_Exp>>;
  asset?: InputMaybe<Asset_Bool_Exp>;
  assetId?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "unrevealed_asset" */
export enum Unrevealed_Asset_Constraint {
  /** unique or primary key constraint */
  UnrevealedAssetAssetIdKey = 'unrevealed_asset_assetId_key',
  /** unique or primary key constraint */
  UnrevealedAssetPkey = 'unrevealed_asset_pkey'
}

/** input type for incrementing numeric columns in table "unrevealed_asset" */
export type Unrevealed_Asset_Inc_Input = {
  assetId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "unrevealed_asset" */
export type Unrevealed_Asset_Insert_Input = {
  asset?: InputMaybe<Asset_Obj_Rel_Insert_Input>;
  assetId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Unrevealed_Asset_Max_Fields = {
  __typename?: 'unrevealed_asset_max_fields';
  assetId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Max_Order_By = {
  assetId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Unrevealed_Asset_Min_Fields = {
  __typename?: 'unrevealed_asset_min_fields';
  assetId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Min_Order_By = {
  assetId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "unrevealed_asset" */
export type Unrevealed_Asset_Mutation_Response = {
  __typename?: 'unrevealed_asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Unrevealed_Asset>;
};

/** on_conflict condition type for table "unrevealed_asset" */
export type Unrevealed_Asset_On_Conflict = {
  constraint: Unrevealed_Asset_Constraint;
  update_columns?: Array<Unrevealed_Asset_Update_Column>;
  where?: InputMaybe<Unrevealed_Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "unrevealed_asset". */
export type Unrevealed_Asset_Order_By = {
  asset?: InputMaybe<Asset_Order_By>;
  assetId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: unrevealed_asset */
export type Unrevealed_Asset_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "unrevealed_asset" */
export enum Unrevealed_Asset_Select_Column {
  /** column name */
  AssetId = 'assetId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  TokenId = 'token_id'
}

/** input type for updating data in table "unrevealed_asset" */
export type Unrevealed_Asset_Set_Input = {
  assetId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Unrevealed_Asset_Stddev_Fields = {
  __typename?: 'unrevealed_asset_stddev_fields';
  assetId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Stddev_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Unrevealed_Asset_Stddev_Pop_Fields = {
  __typename?: 'unrevealed_asset_stddev_pop_fields';
  assetId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Stddev_Pop_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Unrevealed_Asset_Stddev_Samp_Fields = {
  __typename?: 'unrevealed_asset_stddev_samp_fields';
  assetId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Stddev_Samp_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Unrevealed_Asset_Sum_Fields = {
  __typename?: 'unrevealed_asset_sum_fields';
  assetId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Sum_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

/** update columns of table "unrevealed_asset" */
export enum Unrevealed_Asset_Update_Column {
  /** column name */
  AssetId = 'assetId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  TokenId = 'token_id'
}

/** aggregate var_pop on columns */
export type Unrevealed_Asset_Var_Pop_Fields = {
  __typename?: 'unrevealed_asset_var_pop_fields';
  assetId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Var_Pop_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Unrevealed_Asset_Var_Samp_Fields = {
  __typename?: 'unrevealed_asset_var_samp_fields';
  assetId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Var_Samp_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Unrevealed_Asset_Variance_Fields = {
  __typename?: 'unrevealed_asset_variance_fields';
  assetId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "unrevealed_asset" */
export type Unrevealed_Asset_Variance_Order_By = {
  assetId?: InputMaybe<Order_By>;
};

export type GetAssetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssetsQuery = { __typename?: 'query_root', asset: Array<{ __typename?: 'asset', collection_id: number, created_at: any, data_url: string, description: string, external_link?: string | null | undefined, id: number, mystery_box_id?: number | null | undefined, name: string, owner_address: string, token_id: string, type?: string | null | undefined, collection: { __typename?: 'collection', banner_image_url: string, created_at: any, description: string, external_url?: string | null | undefined, featured_image_url?: string | null | undefined, id: number, logo_image_url: string, name: string, slug: string }, mystery_box?: { __typename?: 'mystery_box', end_at: any, eth_price: string, file_url: string, id: number, left_items_count: number, on_chain_box_id: number, published: boolean, revealed: boolean, revealed_at?: any | null | undefined, start_at: any, total_items_count: number, mystery_box_items: Array<{ __typename?: 'mystery_box_item', description: string, external_link?: string | null | undefined, id: number, mystery_box_id: number, name: string, quantity: number }> } | null | undefined }> };

export type GetAssetsByOwnerAddressQueryVariables = Exact<{
  ownerAddress: Scalars['String'];
}>;


export type GetAssetsByOwnerAddressQuery = { __typename?: 'query_root', asset: Array<{ __typename?: 'asset', collection_id: number, created_at: any, data_url: string, description: string, external_link?: string | null | undefined, id: number, mystery_box_id?: number | null | undefined, name: string, owner_address: string, token_id: string, type?: string | null | undefined, collection: { __typename?: 'collection', banner_image_url: string, created_at: any, description: string, external_url?: string | null | undefined, featured_image_url?: string | null | undefined, id: number, logo_image_url: string, name: string, slug: string }, mystery_box?: { __typename?: 'mystery_box', end_at: any, eth_price: string, file_url: string, id: number, left_items_count: number, on_chain_box_id: number, published: boolean, revealed: boolean, revealed_at?: any | null | undefined, start_at: any, total_items_count: number, mystery_box_items: Array<{ __typename?: 'mystery_box_item', description: string, external_link?: string | null | undefined, id: number, mystery_box_id: number, name: string, quantity: number }> } | null | undefined }> };

export type GetCollectionsQueryVariables = Exact<{
  where?: InputMaybe<Collection_Bool_Exp>;
}>;


export type GetCollectionsQuery = { __typename?: 'query_root', collection: Array<{ __typename?: 'collection', banner_image_url: string, created_at: any, description: string, external_url?: string | null | undefined, featured_image_url?: string | null | undefined, id: number, logo_image_url: string, name: string, slug: string }> };

export type GetMysteryBoxByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMysteryBoxByIdQuery = { __typename?: 'query_root', mystery_box_by_pk?: { __typename?: 'mystery_box', total_items_count: number, start_at: any, revealed_at?: any | null | undefined, revealed: boolean, published: boolean, on_chain_box_id: number, left_items_count: number, id: number, file_url: string, eth_price: string, end_at: any, celebrity: string, description: string, rarity: string, title: string, tag: string, mystery_box_items: Array<{ __typename?: 'mystery_box_item', description: string, external_link?: string | null | undefined, id: number, name: string, quantity: number, value: string }> } | null | undefined };

export type GetMysteryBoxByOnChainBoxIdQueryVariables = Exact<{
  onChainBoxId: Scalars['Int'];
}>;


export type GetMysteryBoxByOnChainBoxIdQuery = { __typename?: 'query_root', mystery_box: Array<{ __typename?: 'mystery_box', total_items_count: number, start_at: any, revealed_at?: any | null | undefined, revealed: boolean, published: boolean, on_chain_box_id: number, left_items_count: number, id: number, file_url: string, eth_price: string, end_at: any, celebrity: string, description: string, rarity: string, title: string, tag: string, mystery_box_items: Array<{ __typename?: 'mystery_box_item', description: string, external_link?: string | null | undefined, id: number, name: string, quantity: number, value: string }> }> };

export type GetMysteryBoxesQueryVariables = Exact<{
  where?: InputMaybe<Mystery_Box_Bool_Exp>;
}>;


export type GetMysteryBoxesQuery = { __typename?: 'query_root', mystery_box: Array<{ __typename?: 'mystery_box', total_items_count: number, start_at: any, revealed_at?: any | null | undefined, revealed: boolean, published: boolean, on_chain_box_id: number, left_items_count: number, id: number, eth_price: string, end_at: any, file_url: string, celebrity: string, description: string, rarity: string, title: string, tag: string }> };

export type GetPaymentTokensQueryVariables = Exact<{
  where?: InputMaybe<Payment_Token_Bool_Exp>;
}>;


export type GetPaymentTokensQuery = { __typename?: 'query_root', payment_token: Array<{ __typename?: 'payment_token', address: string, decimals: string, display_name: string, eth_price: any, image_url: string, name: string, symbol: string, usd_price: any }> };

export type GetUnrevealedAssetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnrevealedAssetsQuery = { __typename?: 'query_root', unrevealed_asset: Array<{ __typename?: 'unrevealed_asset', assetId: number, description: string, id: string, image: string, name: string, token_id: string, asset: { __typename?: 'asset', collection_id: number, created_at: any, data_url: string, description: string, external_link?: string | null | undefined, id: number, mystery_box_id?: number | null | undefined, name: string, owner_address: string, token_id: string, type?: string | null | undefined, collection: { __typename?: 'collection', banner_image_url: string, created_at: any, description: string, external_url?: string | null | undefined, featured_image_url?: string | null | undefined, id: number, logo_image_url: string, name: string, slug: string }, mystery_box?: { __typename?: 'mystery_box', end_at: any, eth_price: string, file_url: string, id: number, left_items_count: number, on_chain_box_id: number, published: boolean, revealed: boolean, revealed_at?: any | null | undefined, start_at: any, total_items_count: number, mystery_box_items: Array<{ __typename?: 'mystery_box_item', description: string, external_link?: string | null | undefined, id: number, mystery_box_id: number, name: string, quantity: number }> } | null | undefined } }> };

export type GetUnrevealedAssetsByOwnerAddressQueryVariables = Exact<{
  ownerAddress: Scalars['String'];
}>;


export type GetUnrevealedAssetsByOwnerAddressQuery = { __typename?: 'query_root', unrevealed_asset: Array<{ __typename?: 'unrevealed_asset', assetId: number, description: string, id: string, image: string, name: string, token_id: string, asset: { __typename?: 'asset', collection_id: number, created_at: any, data_url: string, description: string, external_link?: string | null | undefined, id: number, mystery_box_id?: number | null | undefined, name: string, owner_address: string, token_id: string, type?: string | null | undefined, collection: { __typename?: 'collection', banner_image_url: string, created_at: any, description: string, external_url?: string | null | undefined, featured_image_url?: string | null | undefined, id: number, logo_image_url: string, name: string, slug: string }, mystery_box?: { __typename?: 'mystery_box', end_at: any, eth_price: string, file_url: string, id: number, left_items_count: number, on_chain_box_id: number, published: boolean, revealed: boolean, revealed_at?: any | null | undefined, start_at: any, total_items_count: number, mystery_box_items: Array<{ __typename?: 'mystery_box_item', description: string, external_link?: string | null | undefined, id: number, mystery_box_id: number, name: string, quantity: number }> } | null | undefined } }> };

export type BuyBoxMutationVariables = Exact<{
  trxHash: Scalars['String'];
}>;


export type BuyBoxMutation = { __typename?: 'mutation_root', buy_box?: { __typename?: 'BuyBoxOutput', token_id?: string | null | undefined } | null | undefined };

export type RevealMysteryBoxMutationVariables = Exact<{
  id: Scalars['Int'];
  revealed?: InputMaybe<Scalars['Boolean']>;
}>;


export type RevealMysteryBoxMutation = { __typename?: 'mutation_root', update_mystery_box_by_pk?: { __typename?: 'mystery_box', id: number } | null | undefined };

export type GetAssetByTokenIdQueryVariables = Exact<{
  tokenId: Scalars['String'];
}>;


export type GetAssetByTokenIdQuery = { __typename?: 'query_root', asset: Array<{ __typename?: 'asset', collection_id: number, created_at: any, data_url: string, description: string, external_link?: string | null | undefined, id: number, mystery_box_id?: number | null | undefined, name: string, owner_address: string, token_id: string, type?: string | null | undefined, collection: { __typename?: 'collection', banner_image_url: string, created_at: any, description: string, external_url?: string | null | undefined, featured_image_url?: string | null | undefined, id: number, logo_image_url: string, name: string, slug: string }, mystery_box?: { __typename?: 'mystery_box', end_at: any, eth_price: string, file_url: string, id: number, left_items_count: number, on_chain_box_id: number, published: boolean, revealed: boolean, revealed_at?: any | null | undefined, start_at: any, total_items_count: number, mystery_box_items: Array<{ __typename?: 'mystery_box_item', description: string, external_link?: string | null | undefined, id: number, mystery_box_id: number, name: string, quantity: number }> } | null | undefined }> };


export const GetAssetsDocument = gql`
    query GetAssets {
  asset {
    collection_id
    collection {
      banner_image_url
      created_at
      description
      external_url
      featured_image_url
      id
      logo_image_url
      name
      slug
    }
    created_at
    data_url
    description
    external_link
    id
    mystery_box_id
    mystery_box {
      end_at
      eth_price
      file_url
      id
      left_items_count
      on_chain_box_id
      published
      revealed
      revealed_at
      start_at
      total_items_count
      mystery_box_items {
        description
        external_link
        id
        mystery_box_id
        name
        quantity
      }
    }
    name
    owner_address
    token_id
    type
  }
}
    `;

/**
 * __useGetAssetsQuery__
 *
 * To run a query within a React component, call `useGetAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsQuery, GetAssetsQueryVariables>(GetAssetsDocument, options);
      }
export function useGetAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsQuery, GetAssetsQueryVariables>(GetAssetsDocument, options);
        }
export type GetAssetsQueryHookResult = ReturnType<typeof useGetAssetsQuery>;
export type GetAssetsLazyQueryHookResult = ReturnType<typeof useGetAssetsLazyQuery>;
export type GetAssetsQueryResult = Apollo.QueryResult<GetAssetsQuery, GetAssetsQueryVariables>;
export const GetAssetsByOwnerAddressDocument = gql`
    query GetAssetsByOwnerAddress($ownerAddress: String!) {
  asset(where: {owner_address: {_eq: $ownerAddress}}) {
    collection_id
    collection {
      banner_image_url
      created_at
      description
      external_url
      featured_image_url
      id
      logo_image_url
      name
      slug
    }
    created_at
    data_url
    description
    external_link
    id
    mystery_box_id
    mystery_box {
      end_at
      eth_price
      file_url
      id
      left_items_count
      on_chain_box_id
      published
      revealed
      revealed_at
      start_at
      total_items_count
      mystery_box_items {
        description
        external_link
        id
        mystery_box_id
        name
        quantity
      }
    }
    name
    owner_address
    token_id
    type
  }
}
    `;

/**
 * __useGetAssetsByOwnerAddressQuery__
 *
 * To run a query within a React component, call `useGetAssetsByOwnerAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsByOwnerAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsByOwnerAddressQuery({
 *   variables: {
 *      ownerAddress: // value for 'ownerAddress'
 *   },
 * });
 */
export function useGetAssetsByOwnerAddressQuery(baseOptions: Apollo.QueryHookOptions<GetAssetsByOwnerAddressQuery, GetAssetsByOwnerAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsByOwnerAddressQuery, GetAssetsByOwnerAddressQueryVariables>(GetAssetsByOwnerAddressDocument, options);
      }
export function useGetAssetsByOwnerAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsByOwnerAddressQuery, GetAssetsByOwnerAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsByOwnerAddressQuery, GetAssetsByOwnerAddressQueryVariables>(GetAssetsByOwnerAddressDocument, options);
        }
export type GetAssetsByOwnerAddressQueryHookResult = ReturnType<typeof useGetAssetsByOwnerAddressQuery>;
export type GetAssetsByOwnerAddressLazyQueryHookResult = ReturnType<typeof useGetAssetsByOwnerAddressLazyQuery>;
export type GetAssetsByOwnerAddressQueryResult = Apollo.QueryResult<GetAssetsByOwnerAddressQuery, GetAssetsByOwnerAddressQueryVariables>;
export const GetCollectionsDocument = gql`
    query GetCollections($where: collection_bool_exp = {}) {
  collection(where: $where) {
    banner_image_url
    created_at
    description
    external_url
    featured_image_url
    id
    logo_image_url
    name
    slug
  }
}
    `;

/**
 * __useGetCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCollectionsQuery, GetCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(GetCollectionsDocument, options);
      }
export function useGetCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionsQuery, GetCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(GetCollectionsDocument, options);
        }
export type GetCollectionsQueryHookResult = ReturnType<typeof useGetCollectionsQuery>;
export type GetCollectionsLazyQueryHookResult = ReturnType<typeof useGetCollectionsLazyQuery>;
export type GetCollectionsQueryResult = Apollo.QueryResult<GetCollectionsQuery, GetCollectionsQueryVariables>;
export const GetMysteryBoxByIdDocument = gql`
    query GetMysteryBoxById($id: Int!) {
  mystery_box_by_pk(id: $id) {
    total_items_count
    start_at
    revealed_at
    revealed
    published
    on_chain_box_id
    left_items_count
    id
    file_url
    eth_price
    end_at
    celebrity
    description
    rarity
    title
    tag
    mystery_box_items {
      description
      external_link
      id
      name
      quantity
      value
    }
  }
}
    `;

/**
 * __useGetMysteryBoxByIdQuery__
 *
 * To run a query within a React component, call `useGetMysteryBoxByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMysteryBoxByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMysteryBoxByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMysteryBoxByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMysteryBoxByIdQuery, GetMysteryBoxByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMysteryBoxByIdQuery, GetMysteryBoxByIdQueryVariables>(GetMysteryBoxByIdDocument, options);
      }
export function useGetMysteryBoxByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMysteryBoxByIdQuery, GetMysteryBoxByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMysteryBoxByIdQuery, GetMysteryBoxByIdQueryVariables>(GetMysteryBoxByIdDocument, options);
        }
export type GetMysteryBoxByIdQueryHookResult = ReturnType<typeof useGetMysteryBoxByIdQuery>;
export type GetMysteryBoxByIdLazyQueryHookResult = ReturnType<typeof useGetMysteryBoxByIdLazyQuery>;
export type GetMysteryBoxByIdQueryResult = Apollo.QueryResult<GetMysteryBoxByIdQuery, GetMysteryBoxByIdQueryVariables>;
export const GetMysteryBoxByOnChainBoxIdDocument = gql`
    query GetMysteryBoxByOnChainBoxId($onChainBoxId: Int!) {
  mystery_box(where: {on_chain_box_id: {_eq: $onChainBoxId}}) {
    total_items_count
    start_at
    revealed_at
    revealed
    published
    on_chain_box_id
    left_items_count
    id
    file_url
    eth_price
    end_at
    celebrity
    description
    rarity
    title
    tag
    mystery_box_items {
      description
      external_link
      id
      name
      quantity
      value
    }
  }
}
    `;

/**
 * __useGetMysteryBoxByOnChainBoxIdQuery__
 *
 * To run a query within a React component, call `useGetMysteryBoxByOnChainBoxIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMysteryBoxByOnChainBoxIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMysteryBoxByOnChainBoxIdQuery({
 *   variables: {
 *      onChainBoxId: // value for 'onChainBoxId'
 *   },
 * });
 */
export function useGetMysteryBoxByOnChainBoxIdQuery(baseOptions: Apollo.QueryHookOptions<GetMysteryBoxByOnChainBoxIdQuery, GetMysteryBoxByOnChainBoxIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMysteryBoxByOnChainBoxIdQuery, GetMysteryBoxByOnChainBoxIdQueryVariables>(GetMysteryBoxByOnChainBoxIdDocument, options);
      }
export function useGetMysteryBoxByOnChainBoxIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMysteryBoxByOnChainBoxIdQuery, GetMysteryBoxByOnChainBoxIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMysteryBoxByOnChainBoxIdQuery, GetMysteryBoxByOnChainBoxIdQueryVariables>(GetMysteryBoxByOnChainBoxIdDocument, options);
        }
export type GetMysteryBoxByOnChainBoxIdQueryHookResult = ReturnType<typeof useGetMysteryBoxByOnChainBoxIdQuery>;
export type GetMysteryBoxByOnChainBoxIdLazyQueryHookResult = ReturnType<typeof useGetMysteryBoxByOnChainBoxIdLazyQuery>;
export type GetMysteryBoxByOnChainBoxIdQueryResult = Apollo.QueryResult<GetMysteryBoxByOnChainBoxIdQuery, GetMysteryBoxByOnChainBoxIdQueryVariables>;
export const GetMysteryBoxesDocument = gql`
    query GetMysteryBoxes($where: mystery_box_bool_exp = {}) {
  mystery_box(where: $where) {
    total_items_count
    start_at
    revealed_at
    revealed
    published
    on_chain_box_id
    left_items_count
    id
    eth_price
    end_at
    file_url
    celebrity
    description
    rarity
    title
    tag
  }
}
    `;

/**
 * __useGetMysteryBoxesQuery__
 *
 * To run a query within a React component, call `useGetMysteryBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMysteryBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMysteryBoxesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMysteryBoxesQuery(baseOptions?: Apollo.QueryHookOptions<GetMysteryBoxesQuery, GetMysteryBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMysteryBoxesQuery, GetMysteryBoxesQueryVariables>(GetMysteryBoxesDocument, options);
      }
export function useGetMysteryBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMysteryBoxesQuery, GetMysteryBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMysteryBoxesQuery, GetMysteryBoxesQueryVariables>(GetMysteryBoxesDocument, options);
        }
export type GetMysteryBoxesQueryHookResult = ReturnType<typeof useGetMysteryBoxesQuery>;
export type GetMysteryBoxesLazyQueryHookResult = ReturnType<typeof useGetMysteryBoxesLazyQuery>;
export type GetMysteryBoxesQueryResult = Apollo.QueryResult<GetMysteryBoxesQuery, GetMysteryBoxesQueryVariables>;
export const GetPaymentTokensDocument = gql`
    query GetPaymentTokens($where: payment_token_bool_exp = {}) {
  payment_token(where: $where) {
    address
    decimals
    display_name
    eth_price
    image_url
    name
    symbol
    usd_price
  }
}
    `;

/**
 * __useGetPaymentTokensQuery__
 *
 * To run a query within a React component, call `useGetPaymentTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentTokensQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPaymentTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentTokensQuery, GetPaymentTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentTokensQuery, GetPaymentTokensQueryVariables>(GetPaymentTokensDocument, options);
      }
export function useGetPaymentTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentTokensQuery, GetPaymentTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentTokensQuery, GetPaymentTokensQueryVariables>(GetPaymentTokensDocument, options);
        }
export type GetPaymentTokensQueryHookResult = ReturnType<typeof useGetPaymentTokensQuery>;
export type GetPaymentTokensLazyQueryHookResult = ReturnType<typeof useGetPaymentTokensLazyQuery>;
export type GetPaymentTokensQueryResult = Apollo.QueryResult<GetPaymentTokensQuery, GetPaymentTokensQueryVariables>;
export const GetUnrevealedAssetsDocument = gql`
    query GetUnrevealedAssets {
  unrevealed_asset {
    asset {
      collection_id
      collection {
        banner_image_url
        created_at
        description
        external_url
        featured_image_url
        id
        logo_image_url
        name
        slug
      }
      created_at
      data_url
      description
      external_link
      id
      mystery_box_id
      mystery_box {
        end_at
        eth_price
        file_url
        id
        left_items_count
        on_chain_box_id
        published
        revealed
        revealed_at
        start_at
        total_items_count
        mystery_box_items {
          description
          external_link
          id
          mystery_box_id
          name
          quantity
        }
      }
      name
      owner_address
      token_id
      type
    }
    assetId
    description
    id
    image
    name
    token_id
  }
}
    `;

/**
 * __useGetUnrevealedAssetsQuery__
 *
 * To run a query within a React component, call `useGetUnrevealedAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnrevealedAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnrevealedAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnrevealedAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnrevealedAssetsQuery, GetUnrevealedAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnrevealedAssetsQuery, GetUnrevealedAssetsQueryVariables>(GetUnrevealedAssetsDocument, options);
      }
export function useGetUnrevealedAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnrevealedAssetsQuery, GetUnrevealedAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnrevealedAssetsQuery, GetUnrevealedAssetsQueryVariables>(GetUnrevealedAssetsDocument, options);
        }
export type GetUnrevealedAssetsQueryHookResult = ReturnType<typeof useGetUnrevealedAssetsQuery>;
export type GetUnrevealedAssetsLazyQueryHookResult = ReturnType<typeof useGetUnrevealedAssetsLazyQuery>;
export type GetUnrevealedAssetsQueryResult = Apollo.QueryResult<GetUnrevealedAssetsQuery, GetUnrevealedAssetsQueryVariables>;
export const GetUnrevealedAssetsByOwnerAddressDocument = gql`
    query GetUnrevealedAssetsByOwnerAddress($ownerAddress: String!) {
  unrevealed_asset(where: {asset: {owner_address: {_eq: $ownerAddress}}}) {
    asset {
      collection_id
      collection {
        banner_image_url
        created_at
        description
        external_url
        featured_image_url
        id
        logo_image_url
        name
        slug
      }
      created_at
      data_url
      description
      external_link
      id
      mystery_box_id
      mystery_box {
        end_at
        eth_price
        file_url
        id
        left_items_count
        on_chain_box_id
        published
        revealed
        revealed_at
        start_at
        total_items_count
        mystery_box_items {
          description
          external_link
          id
          mystery_box_id
          name
          quantity
        }
      }
      name
      owner_address
      token_id
      type
    }
    assetId
    description
    id
    image
    name
    token_id
  }
}
    `;

/**
 * __useGetUnrevealedAssetsByOwnerAddressQuery__
 *
 * To run a query within a React component, call `useGetUnrevealedAssetsByOwnerAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnrevealedAssetsByOwnerAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnrevealedAssetsByOwnerAddressQuery({
 *   variables: {
 *      ownerAddress: // value for 'ownerAddress'
 *   },
 * });
 */
export function useGetUnrevealedAssetsByOwnerAddressQuery(baseOptions: Apollo.QueryHookOptions<GetUnrevealedAssetsByOwnerAddressQuery, GetUnrevealedAssetsByOwnerAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnrevealedAssetsByOwnerAddressQuery, GetUnrevealedAssetsByOwnerAddressQueryVariables>(GetUnrevealedAssetsByOwnerAddressDocument, options);
      }
export function useGetUnrevealedAssetsByOwnerAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnrevealedAssetsByOwnerAddressQuery, GetUnrevealedAssetsByOwnerAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnrevealedAssetsByOwnerAddressQuery, GetUnrevealedAssetsByOwnerAddressQueryVariables>(GetUnrevealedAssetsByOwnerAddressDocument, options);
        }
export type GetUnrevealedAssetsByOwnerAddressQueryHookResult = ReturnType<typeof useGetUnrevealedAssetsByOwnerAddressQuery>;
export type GetUnrevealedAssetsByOwnerAddressLazyQueryHookResult = ReturnType<typeof useGetUnrevealedAssetsByOwnerAddressLazyQuery>;
export type GetUnrevealedAssetsByOwnerAddressQueryResult = Apollo.QueryResult<GetUnrevealedAssetsByOwnerAddressQuery, GetUnrevealedAssetsByOwnerAddressQueryVariables>;
export const BuyBoxDocument = gql`
    mutation BuyBox($trxHash: String!) {
  buy_box(object: {trx_hash: $trxHash}) {
    token_id
  }
}
    `;
export type BuyBoxMutationFn = Apollo.MutationFunction<BuyBoxMutation, BuyBoxMutationVariables>;

/**
 * __useBuyBoxMutation__
 *
 * To run a mutation, you first call `useBuyBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyBoxMutation, { data, loading, error }] = useBuyBoxMutation({
 *   variables: {
 *      trxHash: // value for 'trxHash'
 *   },
 * });
 */
export function useBuyBoxMutation(baseOptions?: Apollo.MutationHookOptions<BuyBoxMutation, BuyBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyBoxMutation, BuyBoxMutationVariables>(BuyBoxDocument, options);
      }
export type BuyBoxMutationHookResult = ReturnType<typeof useBuyBoxMutation>;
export type BuyBoxMutationResult = Apollo.MutationResult<BuyBoxMutation>;
export type BuyBoxMutationOptions = Apollo.BaseMutationOptions<BuyBoxMutation, BuyBoxMutationVariables>;
export const RevealMysteryBoxDocument = gql`
    mutation RevealMysteryBox($id: Int!, $revealed: Boolean = true) {
  update_mystery_box_by_pk(pk_columns: {id: $id}, _set: {revealed: $revealed}) {
    id
  }
}
    `;
export type RevealMysteryBoxMutationFn = Apollo.MutationFunction<RevealMysteryBoxMutation, RevealMysteryBoxMutationVariables>;

/**
 * __useRevealMysteryBoxMutation__
 *
 * To run a mutation, you first call `useRevealMysteryBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevealMysteryBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revealMysteryBoxMutation, { data, loading, error }] = useRevealMysteryBoxMutation({
 *   variables: {
 *      id: // value for 'id'
 *      revealed: // value for 'revealed'
 *   },
 * });
 */
export function useRevealMysteryBoxMutation(baseOptions?: Apollo.MutationHookOptions<RevealMysteryBoxMutation, RevealMysteryBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevealMysteryBoxMutation, RevealMysteryBoxMutationVariables>(RevealMysteryBoxDocument, options);
      }
export type RevealMysteryBoxMutationHookResult = ReturnType<typeof useRevealMysteryBoxMutation>;
export type RevealMysteryBoxMutationResult = Apollo.MutationResult<RevealMysteryBoxMutation>;
export type RevealMysteryBoxMutationOptions = Apollo.BaseMutationOptions<RevealMysteryBoxMutation, RevealMysteryBoxMutationVariables>;
export const GetAssetByTokenIdDocument = gql`
    query GetAssetByTokenId($tokenId: String!) {
  asset(where: {token_id: {_eq: $tokenId}}) {
    collection_id
    collection {
      banner_image_url
      created_at
      description
      external_url
      featured_image_url
      id
      logo_image_url
      name
      slug
    }
    created_at
    data_url
    description
    external_link
    id
    mystery_box_id
    mystery_box {
      end_at
      eth_price
      file_url
      id
      left_items_count
      on_chain_box_id
      published
      revealed
      revealed_at
      start_at
      total_items_count
      mystery_box_items {
        description
        external_link
        id
        mystery_box_id
        name
        quantity
      }
    }
    name
    owner_address
    token_id
    type
  }
}
    `;

/**
 * __useGetAssetByTokenIdQuery__
 *
 * To run a query within a React component, call `useGetAssetByTokenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByTokenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByTokenIdQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useGetAssetByTokenIdQuery(baseOptions: Apollo.QueryHookOptions<GetAssetByTokenIdQuery, GetAssetByTokenIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetByTokenIdQuery, GetAssetByTokenIdQueryVariables>(GetAssetByTokenIdDocument, options);
      }
export function useGetAssetByTokenIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetByTokenIdQuery, GetAssetByTokenIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetByTokenIdQuery, GetAssetByTokenIdQueryVariables>(GetAssetByTokenIdDocument, options);
        }
export type GetAssetByTokenIdQueryHookResult = ReturnType<typeof useGetAssetByTokenIdQuery>;
export type GetAssetByTokenIdLazyQueryHookResult = ReturnType<typeof useGetAssetByTokenIdLazyQuery>;
export type GetAssetByTokenIdQueryResult = Apollo.QueryResult<GetAssetByTokenIdQuery, GetAssetByTokenIdQueryVariables>;