import { configureStore } from '@reduxjs/toolkit';

import userSlice from 'reducers/userSlice';
import modalsControllerSlice from 'reducers/modalsControllerSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    modalsController: modalsControllerSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
export type AppDispatch = typeof store.dispatch;
