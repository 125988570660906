import * as React from 'react';
import styled from 'styled-components/macro';
import { useParams, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import {
  ChainId,
  CHAIN_ID_BSC,
  CHAIN_ID_POLYGON,
} from '@certusone/wormhole-sdk';
import { ethers } from 'ethers';
import { FaChevronDown } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Collapsible from 'react-collapsible';

import { useGetAssetByTokenIdQuery } from 'generated/graphql';
import {
  ChainInfo,
  getChainsWithNftSupport,
  getCoreBridgeAddressForChain,
  getNftBridgeAddressForChain,
} from 'utils/web3/wormhole/constants';
import { torusConnector } from 'utils/web3/connectors/torusConnector';
import config from 'config';

import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
// import LabelElement from 'components/common/LabelElement';
// import StyledSelect from 'components/common/StyledSelect';
import FlexColumnWrapper from 'components/common/wrappers/FlexColumnWrapper';
// import Button from 'components/common/Button';
import Target from 'components/wormhole/Target';
import SendNft from 'components/wormhole/SendNft';
import RedeemNft from 'components/wormhole/RedeemNft';
import TransferModal from 'components/modals/TransferModal';

import { transferFromEthUtil } from 'utils/web3/wormhole/transferFromEthUtil';
import { waitUntilEthTxObserved } from 'utils/web3/wormhole/waitUntilEthTxObserved';
import { checkWrappedNftContractPresent } from 'utils/web3/wormhole/checkWrappedNftContractPresent';
import { redeemOnEthUtil } from 'utils/web3/wormhole/redeemOnEthUtil';

const WormholeWrapper = styled.div`
  padding: 0 24px;
  height: 100vh;
  .t-title {
    margin-top: 40px;
    font-weight: 900;
    font-size: 24px;
    line-height: 112.02%;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .collapsible-title {
    align-items: center;
    column-gap: 8px;
    margin: 32px 0;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    span {
      width: 24px;
      height: 24px;
      justify-content: center;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 112.02%;

      color: #000000;
      background: #ffffff;
      border-radius: 42px;
    }

    font-weight: 700;
    font-size: 18px;
    line-height: 112.02%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  input[type='text'] {
    border: 1px solid #ffffff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    /* identical to box height, or 140% */
    background: #000;
    color: #ffffff;

    padding: 8px;
  }

  .nft-card-wrapper {
    background: #fff;
    border-radius: 16px;
    margin-bottom: 32px;

    .nft-image {
      img {
        width: 100%;
        height: 311px;
        border-radius: 16px;
      }
    }
    .nft-description {
      font-weight: 700;
      font-size: 14px;
      padding: 16px;
      line-height: 20px;
      color: #000000;
      opacity: 0.8;
    }
  }

  .next-button {
    width: 100%;
    height: 40px;
    font-weight: 700;
    margin: 20px 0;
    font-size: 16px;
    line-height: 112.02%;
    color: #ffffff;
    background: #0072de;
    border-radius: 42px;
  }

  .divider {
    width: 100%;
    height: 2px;

    background: #ffffff;
  }
`;

interface WormholeProps {}

const Wormhole = (_: WormholeProps): JSX.Element => {
  const [isTestnet, setIsTestNet] = React.useState(false);
  const [baseChainId, setBaseChainId] = React.useState<ChainId>(CHAIN_ID_BSC);
  const [redemptionChainId, setRedemptionChainId] =
    React.useState<ChainId>(CHAIN_ID_POLYGON);
  const [redeemableChains, setRedeemableChains] = React.useState<ChainInfo[]>(
    []
  );
  const [baseChainSignedVAA, setBaseChainSignedVAA] =
    React.useState<Uint8Array>();

  const [isTransferringNft, setIsTransferringNft] = React.useState(false);
  const [isRedeemingNft, setIsRedeemingNft] = React.useState(false);
  const [redeemptionSuccessful, setRedemptionSuccessful] =
    React.useState(false);
  const [transferTxHash, setTransferTxHash] = React.useState('');
  const [redeemTxHash, setRedeemTxHash] = React.useState('');

  const { tokenId } = useParams();
  const navigate = useNavigate();

  const { account, library, active, activate } = useWeb3React();

  // On page mount, if wallet not connect, activate wallet connection.
  // On page dismount, ensure connected chain is base chain
  React.useEffect(() => {
    if (!active) {
      activate(torusConnector(config.chainId), console.error);
    }

    return () => {
      if (active) {
        activate(torusConnector(config.chainId));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Go back if either contract address or tokenId is missing
  React.useEffect(() => {
    if (!tokenId) {
      navigate(-1);
    }
  }, [tokenId, navigate]);

  // // Check if user logged in
  // React.useEffect(() => {
  //   if (!account) {
  //     navigate(-1);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // TODO Fetch token information.
  const { data: assetData } = useGetAssetByTokenIdQuery({
    skip: !tokenId,
    variables: {
      tokenId: tokenId as string,
    },
  });

  // TODO Check if logged in user is owner of token
  React.useEffect(() => {}, []);

  // Update redemption chains list
  React.useEffect(() => {
    if (baseChainId) {
      const redeemableChainsArray = getChainsWithNftSupport.filter(
        ({ id }) => id !== baseChainId
      );
      setRedeemableChains(redeemableChainsArray);
    }
  }, [baseChainId]);

  const handleTokenTransferFromBaseChain = async () => {
    try {
      const transaction = await transferFromEthUtil(
        getNftBridgeAddressForChain(baseChainId, isTestnet),
        config.blockchainAddresses.specialContractForWormhole as string,
        tokenId as string,
        account as string,
        redemptionChainId,
        library.getSigner()
      );
      return transaction;
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    }
  };

  const handleTranferTransactionObservation = async (
    transaction: ethers.ContractReceipt
  ) => {
    try {
      console.time('signedVAA');
      const signedVAA = await waitUntilEthTxObserved(
        transaction,
        baseChainId,
        getCoreBridgeAddressForChain(baseChainId, isTestnet),
        getNftBridgeAddressForChain(baseChainId, isTestnet)
      );
      console.timeEnd('signedVAA');
      return signedVAA;
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    }
  };

  const switchTorusChainToRedemptionChain = async () => {
    try {
      activate(torusConnector(137), (error: any) => {
        throw new Error(error);
      });
      // Add sleep of 2 second to ensure library change is in effect.
      await new Promise((r) => setTimeout(r, 2000));
      return;
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    }
  };

  const handleTokenTransferToWormholeNftBridge = async () => {
    try {
      setIsTransferringNft(true);
      const transaction = await handleTokenTransferFromBaseChain();

      if (!transaction) throw new Error('Transaction not found!');

      setTransferTxHash(transaction?.transactionHash);

      const signedVAA = await handleTranferTransactionObservation(transaction);

      if (!signedVAA) throw new Error('Signed VAA not found!');

      setBaseChainSignedVAA(signedVAA);
      await switchTorusChainToRedemptionChain();
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    } finally {
      setIsTransferringNft(false);
    }
  };

  // For checking if wrapped NFT contract is present,
  // Token bridge address need to be of the chain where we want to check presence of this wrapped contract.
  // For example if we need to check if Polygon contract is present on BSC
  // tokenBridgeAddress will be BSC_NFT_BRIDGE_ADDRESS
  // foreignChainId will be polygon chain id
  // tokenAddress will be contract address of ERC721 token.

  // ! If wrapped contract not found, attestation may be necessary

  const handleWrappedContractPresenceCheck = async () => {
    try {
      if (!redemptionChainId) throw new Error('Redemption chain not set!');

      const result = await checkWrappedNftContractPresent({
        nftBridgeAddress: getNftBridgeAddressForChain(redemptionChainId),
        provider: library,
        originChainId: baseChainId,
        tokenAddress: config.blockchainAddresses.specialContractForWormhole,
      });
      console.log(result);
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    }
  };

  const handleTokenRedemptionRequest = async () => {
    try {
      if (!redemptionChainId) throw new Error('Redemption chain not set!');

      if (!baseChainSignedVAA) throw new Error('Signed VAA not found!');

      const redeemTransaction = await redeemOnEthUtil(
        baseChainSignedVAA,
        library.getSigner(),
        getNftBridgeAddressForChain(redemptionChainId)
      );

      return redeemTransaction;
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    }
  };

  const handleRedeemClick = async () => {
    try {
      setIsRedeemingNft(true);

      const redemptionTransaction = await handleTokenRedemptionRequest();
      console.log(redemptionTransaction);

      if (redemptionTransaction) {
        setRedeemTxHash(redemptionTransaction?.transactionHash);
        setRedemptionSuccessful(true);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.[0]?.message || error?.message || JSON.stringify(error)
      );
    } finally {
      setIsRedeemingNft(false);
    }
  };

  return (
    <WormholeWrapper>
      <div className="t-title">NFT - Chain Bridge</div>

      <Collapsible
        open={true}
        trigger={
          <div className="collapsible-title">
            <div>
              <span>1</span> Source
            </div>
            <FaChevronDown />
          </div>
        }
      >
        <FlexColumnWrapper className="nft-card-wrapper">
          <div className="nft-image">
            <img
              src={
                assetData?.asset?.[0]?.data_url ||
                'https://images.unsplash.com/photo-1516223725307-6f76b9ec8742?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80'
              }
              alt=""
            />
          </div>
          <div className="nft-description">
            {assetData?.asset?.[0]?.name ||
              assetData?.asset?.[0]?.description ||
              'Lorem Ipsum Dolor Amet'}
          </div>
        </FlexColumnWrapper>
      </Collapsible>

      <div className="divider" />

      <Collapsible
        open={true}
        trigger={
          <FlexRowWrapper className="collapsible-title">
            <div>
              <span>2</span> Target
            </div>
            <FaChevronDown />
          </FlexRowWrapper>
        }
      >
        <Target
          accountAddress={account ?? ''}
          tokenContractAddress={
            config.blockchainAddresses.specialContractForWormhole
          }
          tokenId={tokenId}
        />
      </Collapsible>

      <div className="divider" />

      <Collapsible
        open={true}
        trigger={
          <div className="collapsible-title">
            <div>
              <span>3</span> Send NFT
            </div>
            <FaChevronDown />
          </div>
        }
      >
        <SendNft
          disabled={Boolean(baseChainSignedVAA)}
          loading={isTransferringNft}
          explorerTransactionUrl={
            transferTxHash && `https://bscscan.com/tx/${transferTxHash}`
          }
          onTransferClick={handleTokenTransferToWormholeNftBridge}
        />
      </Collapsible>

      <div className="divider" />

      <Collapsible
        open={true}
        trigger={
          <div className="collapsible-title">
            <div>
              <span>4</span> Redeem NFT
            </div>
            <FaChevronDown />
          </div>
        }
      >
        <RedeemNft
          canRedeem={Boolean(baseChainSignedVAA) && !redeemptionSuccessful}
          loading={isRedeemingNft}
          explorerTransactionUrl={
            redeemTxHash && `https://polygonscan.com/tx/${redeemTxHash}`
          }
          onRedeemClick={handleRedeemClick}
        />
      </Collapsible>
    </WormholeWrapper>
  );
};

export default Wormhole;
