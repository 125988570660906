import { useState } from 'react';
import styled from 'styled-components/macro';

import FlexColumnWrapper from './common/wrappers/FlexColumnWrapper';

const CustomDropdownWrapper = styled.div`
  .custom-dropdown-root {
    position: relative;
    .ellipsis {
      margin-top: 50px;
    }
    cursor: pointer;
    /* margin-top: 15px; */
    width: 100%;
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    border-radius: 2px;
    padding: 12px;
  }
  .custom-dropdown-options {
    position: absolute;
    border: none;
    border-radius: 2px;
    margin-left: -7%;
    width: fit-content;
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    .option {
      /* background: ${({ theme }) => theme.text['700']}; */
      white-space: nowrap;
      padding: 8px;
      height: 100%;
      /* color: ${({ theme }) => theme.text['400']}; */
      cursor: pointer;
      width: 100%;
      z-index: 1;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 112.02%;
      color: #0c0c0c;
      &:hover {
        background: #0c0c0c11;
        border-radius: 8px;
      }
    }
  }
`;

interface CustomDropdownProps {
  labelElement?: JSX.Element;
  optionsElement?: JSX.Element;
}

const CustomDropdown = ({
  labelElement,
  optionsElement,
}: CustomDropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomDropdownWrapper>
      <div onClick={() => setIsOpen(!isOpen)} className="custom-dropdown-root">
        {labelElement}
      </div>
      {isOpen && (
        <FlexColumnWrapper className="custom-dropdown-options">
          {optionsElement}
        </FlexColumnWrapper>
      )}
    </CustomDropdownWrapper>
  );
};

export default CustomDropdown;
