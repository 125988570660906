import {
  parseSequenceFromLogEth,
  getEmitterAddressEth,
  getSignedVAAWithRetry,
  ChainId,
} from '@certusone/wormhole-sdk';
import ethers from 'ethers';
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport';

import { getWormholeRpcHosts } from './constants';

async function waitUntilEthTxObserved(
  receipt: ethers.ContractReceipt,
  chainId: ChainId,
  coreBridgeAddress: string,
  nftBridgeAddress: string,
  isTestnet?: boolean
): Promise<Uint8Array> {
  // get the sequence from the logs (needed to fetch the vaa)
  let sequence = parseSequenceFromLogEth(receipt, coreBridgeAddress);
  let emitterAddress = getEmitterAddressEth(nftBridgeAddress);
  // poll until the guardian(s) witness and sign the vaa
  const { vaaBytes: signedVAA } = await getSignedVAAWithRetry(
    getWormholeRpcHosts(isTestnet),
    chainId,
    emitterAddress,
    sequence,
    {
      transport: NodeHttpTransport(),
    }
  );
  return signedVAA;
}

export { waitUntilEthTxObserved };
