import * as React from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from 'react-icons/hi';

import FlexRowWrapper from '../wrappers/FlexRowWrapper';
import FlexColumnWrapper from '../wrappers/FlexColumnWrapper';
import CheckoutTable from 'components/checkout/CheckoutTable';
import Button from '../Button';

const EventDetailSectionWrapper = styled(FlexColumnWrapper)`
  row-gap: 8px;

  .event-image {
    margin-top: -52px;
    height: 433px;
    z-index: -1;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      inset: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #0c0c0c 8.58%,
        rgba(168, 163, 158, 0) 29.45%
      );
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #0c0c0c 8.58%,
        rgba(168, 163, 158, 0) 40.62%
      );
      transform: rotate(-180deg);
    }
  }
  .t-price {
    font-weight: 900;
    font-size: 32px;
    line-height: 112.02%;
    /* identical to box height, or 36px */
    margin-top: -60px;

    color: #ffcc00;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .t-artist-name {
    font-weight: 900;
    font-size: 32px;
    line-height: 112.02%;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .t-event-name,
  .t-ticket-count {
    font-weight: 700;
    font-size: 16px;
    line-height: 112.02%;
    color: #ffffff;
    z-index: 2;
  }

  .t-ticket-count {
    width: 32px;
    display: grid;
    place-items: center;
  }

  .event-info-wrapper {
    row-gap: 8px;
    z-index: 2;

    padding: 0 25px;
  }
  .count-buy-now-wrapper {
    align-items: center;
    margin-top: 24px;
    column-gap: 16px;

    .ticket-count-wrapper {
      width: fit-content;
      align-items: center;
      gap: 8px;

      .ticket-button {
        border-radius: 42px;
        width: 40px;
        height: 40px;
        padding: 0;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .buy-now-range-wrapper {
      row-gap: 8px;
      justify-content: center;
      align-items: center;

      .buy-now-button {
        width: 100%;
        padding: 12px;
        font-weight: 700;
        font-size: 16px;
        line-height: 112.02%;
        /* identical to box height, or 18px */

        color: #0072de;
      }
      .t-duration {
        font-weight: 500;
        font-size: 12px;
        line-height: 112.02%;
        color: #ffffff;
      }
    }
  }
`;

interface EventDetailSectionProps {
  mysteryBoxId?: number;
  eventImage: string;
  artistName: string;
  eventName: string;
  price: number | string;
  duration: string;
  isCheckout?: boolean;
  quantity?: number;
}

const EventDetailSection = ({
  mysteryBoxId,
  eventImage,
  artistName,
  eventName,
  price,
  duration,
  isCheckout = false,
  quantity = 1,
}: EventDetailSectionProps): JSX.Element => {
  const [ticketCount, setTicketCount] = React.useState(quantity ?? 1);

  const navigate = useNavigate();

  return (
    <EventDetailSectionWrapper>
      <div className="event-image">
        <img src={eventImage} alt="" />
      </div>
      <FlexColumnWrapper className="event-info-wrapper">
        <div className="t-price">${price}</div>
        <div className="t-artist-name">{artistName}</div>
        <div className="t-event-name">{eventName}</div>
        {!isCheckout ? (
          <FlexRowWrapper className="count-buy-now-wrapper">
            <FlexRowWrapper className="ticket-count-wrapper">
              <Button
                onClick={() =>
                  setTicketCount(ticketCount === 1 ? 1 : ticketCount - 1)
                }
                type="tertiary"
                className="ticket-button"
              >
                <HiOutlineMinusCircle />
              </Button>
              <div className="t-ticket-count">{ticketCount}</div>
              <Button
                onClick={() => setTicketCount(ticketCount + 1)}
                type="tertiary"
                className="ticket-button"
              >
                <HiOutlinePlusCircle />
              </Button>
            </FlexRowWrapper>
            <FlexColumnWrapper className="buy-now-range-wrapper">
              <Button
                type="tertiary"
                className="buy-now-button"
                onClick={() =>
                  navigate(
                    `/checkout/${mysteryBoxId}?quantity=${ticketCount}`,
                    {
                      state: { quantity: ticketCount },
                    }
                  )
                }
              >
                Buy Now
              </Button>
              <div className="t-duration">{duration}</div>
            </FlexColumnWrapper>
          </FlexRowWrapper>
        ) : (
          <CheckoutTable quantity={quantity} />
        )}
      </FlexColumnWrapper>
    </EventDetailSectionWrapper>
  );
};

export default EventDetailSection;
