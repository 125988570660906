import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalsControllerState {
  showIncorrectNetworkModal: boolean;
  showInsufficientFundsModal: boolean;
  showInteractionBlockingModal: boolean;
  showConnectWalletModal: boolean;
  sufficientFundsAmount: string | number;
}

const initialState: ModalsControllerState = {
  showIncorrectNetworkModal: false,
  showInsufficientFundsModal: false,
  showInteractionBlockingModal: false,
  showConnectWalletModal: false,
  sufficientFundsAmount: '100 BNB',
};

const modalsControllerSlice = createSlice({
  name: 'modalsController',
  initialState,
  reducers: {
    showIncorrectNetworkModal: (state) => ({
      ...state,
      showIncorrectNetworkModal: true,
    }),
    hideIncorrectNetworkModal: (state) => ({
      ...state,
      showIncorrectNetworkModal: false,
    }),
    showInsufficientFundsModal: (state, action: PayloadAction<string>) => ({
      ...state,
      showInsufficientFundsModal: true,
      sufficientFundsAmount: action.payload,
    }),
    hideInsufficientFundsModal: (state) => ({
      ...state,
      showInsufficientFundsModal: false,
    }),
    showInteractionBlockingModal: (state) => ({
      ...state,
      showInteractionBlockingModal: true,
    }),
    hideInteractionBlockingModal: (state) => ({
      ...state,
      showInteractionBlockingModal: false,
    }),
    showConnectWalletModal: (state) => ({
      ...state,
      showConnectWalletModal: true,
    }),
    hideConnectWalletModal: (state) => ({
      ...state,
      showConnectWalletModal: false,
    }),
  },
});

export const {
  showIncorrectNetworkModal,
  hideIncorrectNetworkModal,
  showInsufficientFundsModal,
  hideInsufficientFundsModal,
  showInteractionBlockingModal,
  hideInteractionBlockingModal,
  showConnectWalletModal,
  hideConnectWalletModal,
} = modalsControllerSlice.actions;

export default modalsControllerSlice;
