import { css, createGlobalStyle } from 'styled-components/macro';

import { cssVariables } from './cssVariables';

import 'react-gallery-carousel/dist/index.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-alice-carousel/lib/alice-carousel.css';
const globalCss = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    user-select: none;
    -webkit-user-select: none; /* disable text select */
    -webkit-touch-callout: none; /* disable callout, image save panel (popup) */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; /* "turn off" link highlight */
  }

  body {
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    background: var(--main-background-color);
    color: var(--main-text-color);
    background: #000;
  }

  h1,
  h2,
  h3 {
    text-rendering: optimizeLegibility;
  }

  a:focus {
    outline: 0; // Firefox (remove border on link click)
  }
  /* table styled */
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    font-weight: 700;
    font-size: 14px;
    line-height: 152.02%;
    color: #ffffff;
    padding: 12px;
  }
  td {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 12px;
    opacity: 0.8;
  }
  tr {
    text-align: center;
    border: 1px solid #dddddd;
  }
`;

const GlobalStyles = createGlobalStyle`
  ${cssVariables}
  ${globalCss};
`;

export { GlobalStyles };
