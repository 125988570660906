import * as React from 'react';
import styled from 'styled-components/macro';
import AliceCarousel from 'react-alice-carousel';
import { useNavigate } from 'react-router-dom';

import { GetMysteryBoxesQuery } from 'generated/graphql';

import FlexRowWrapper from './wrappers/FlexRowWrapper';
import FlexColumnWrapper from './wrappers/FlexColumnWrapper';
import Button from './Button';

const CarouselElementWrapper = styled.div`
  position: relative;

  .bullet {
    width: 20%;
    height: 10px;
    width: 30px;
  }

  .carousel-image-wrapper {
    width: 100%;
    height: 400px;
    z-index: -1;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      inset: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #0c0c0c 8.58%,
        rgba(168, 163, 158, 0) 29.45%
      );
      z-index: 10;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #0c0c0c 8.58%,
        rgba(168, 163, 158, 0) 40.62%
      );
      transform: rotate(-180deg);
      z-index: 10;
    }
  }

  .thumbnail-wrapper {
    padding: 0 20px;
    margin-top: 24px;
    width: 100%;

    column-gap: 8px;
    overflow-x: auto;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .thumbnail-image {
      img {
        width: 80px;
        height: 80px;
        border-radius: 16px;
        transform: border 0.5s;
        object-fit: cover;

        &.active {
          transform: border 0.5s;
          border: 1px solid #2e2d2d;
        }
      }
    }
  }

  .alice-carousel__dots {
    .alice-carousel__dots-item {
      &:not(.__custom):hover {
        background-color: #2e2d2d;
        background: #2e2d2d;
      }
      background-color: #2e2d2d;
      background: #2e2d2d;
      margin-right: 8px;

      width: 15%;
      height: 2px;
      border-radius: 0;
      &.__active {
        background-color: #ffcc00;
      }
    }
  }

  .current-carousel-info {
    position: absolute;
    left: 25px;
    z-index: 2;
    bottom: 138px;
    width: fit-content;
    .exclusive-button {
      font-weight: 700;
      font-size: 12px;
      line-height: 112.02%;
      color: #000000;
      margin-bottom: 8px;
    }
    .t-artist-name {
      font-weight: 900;
      font-size: 32px;
      line-height: 112.02%;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .t-artist-info {
      font-weight: 700;
      font-size: 16px;
      line-height: 112.02%;
      color: #ffffff;
      margin: 4px 0 16px 0;
    }
    .buy-now-duration-wrapper {
      align-items: center;
      column-gap: 16px;
      .t-duration {
        font-weight: 500;
        font-size: 12px;
        line-height: 112.02%;
        color: #ffffff;
      }
    }
  }

  .blurred-area {
    width: 100%;
    height: 20px;
    position: absolute;
    background: #000;
    z-index: 1;
    filter: blur(10px);
    &.top {
      top: 0px;
    }
    &.bottom {
      top: 400px;
    }
  }
`;

interface CarouselElementProps {
  mysteryBoxes: GetMysteryBoxesQuery['mystery_box'];
}

const CarouselElement = ({
  mysteryBoxes,
}: CarouselElementProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const navigate = useNavigate();

  return (
    <CarouselElementWrapper>
      {/* <div className="blurred-area top" />
      <div className="blurred-area bottom" /> */}

      <AliceCarousel
        mouseTracking
        activeIndex={currentIndex}
        autoPlayControls={false}
        disableButtonsControls
        onSlideChanged={(e) => setCurrentIndex(e?.slide)}
        onSlideChange={(e) => setCurrentIndex(e?.slide)}
        items={mysteryBoxes.map((box) => (
          <div className="carousel-image-wrapper">
            <img src={box?.file_url} alt="" />
          </div>
        ))}
      />

      <FlexRowWrapper className="thumbnail-wrapper">
        {mysteryBoxes.map((box, index: number) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className="thumbnail-image"
          >
            <img
              className={`${currentIndex === index && 'active'}`}
              src={box?.file_url}
              alt=""
            />
          </div>
        ))}
      </FlexRowWrapper>
      <FlexColumnWrapper className="current-carousel-info">
        {currentIndex === 0 && (
          <Button
            className="exclusive-button"
            onClick={() => console.log('on exclusive click')}
          >
            Exclusive Event
          </Button>
        )}
        <div className="t-artist-name">
          {mysteryBoxes[currentIndex]?.celebrity ||
            mysteryBoxes[currentIndex]?.title ||
            'Maroon 5'}
        </div>
        <div className="t-artist-info">
          {mysteryBoxes[currentIndex]?.title ||
            mysteryBoxes[currentIndex]?.description ||
            'World tour 2022 - Mystery box'}
        </div>
        <FlexRowWrapper className="buy-now-duration-wrapper">
          <Button
            type="tertiary"
            onClick={() => {
              navigate(`/event/${mysteryBoxes[currentIndex]?.id}`);
            }}
          >
            Buy Now
          </Button>
          <div className="t-duration">10D : 20H : 32M</div>
        </FlexRowWrapper>
      </FlexColumnWrapper>
    </CarouselElementWrapper>
  );
};

export default CarouselElement;
