import * as React from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetAssetByTokenIdQuery } from 'generated/graphql';

import FlexColumnWrapper from 'components/common/wrappers/FlexColumnWrapper';
import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
import CustomDropdown from 'components/CustomDropdown';

import mysteryBoxCover from 'assets/mystery-box-covers/large/mysterybox-cover-1.png';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';

const NftDetailsWrapper = styled(FlexColumnWrapper)`
  padding-bottom: 40px;

  .nft-image {
    margin-top: -52px;
    z-index: -1;
    height: 433px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #0c0c0c 8.58%,
        rgba(168, 163, 158, 0) 29.45%
      );
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #0c0c0c 8.58%,
        rgba(168, 163, 158, 0) 40.62%
      );
      transform: rotate(-180deg);
    }
  }

  .rarity-cta-wrapper {
    justify-content: space-between;
    margin-top: -20px;
    width: 100%;
    padding: 0 20px;
    align-items: center;

    .rarity-label {
      width: fit-content;
      height: fit-content;
      padding: 8px 16px;
      background: #ffcc00;
      border-radius: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.18px;
      color: #000000;
    }
    .cta-wrapper {
      justify-content: flex-end;
      align-items: center;
      gap: 16px;

      .t-more {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.18px;
        color: #ffffff;
      }
    }
  }
  .t-name {
    font-weight: 900;
    font-size: 32px;
    line-height: 112.02%;
    color: #ffffff;
    margin-top: 8px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .t-description {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin: 8px auto 16px;
  }
  .nft-info-wrapper {
    padding: 0 24px;
  }

  th {
    text-align: left;
  }

  td {
    text-align: right;
  }
`;

interface NftDetailsProps {}

const NftDetails = (_: NftDetailsProps): JSX.Element => {
  const navigate = useNavigate();
  const { tokenId } = useParams();

  React.useEffect(() => {
    if (!tokenId) {
      navigate(-1);
    }
  }, [tokenId, navigate]);

  const { data: assetData } = useGetAssetByTokenIdQuery({
    skip: !tokenId,
    variables: { tokenId: tokenId as string },
  });

  return (
    <NftDetailsWrapper>
      <div className="nft-image">
        <img src={assetData?.asset?.[0]?.data_url || mysteryBoxCover} alt="" />
      </div>
      <FlexRowWrapper className="rarity-cta-wrapper">
        <div className="rarity-label">Legendary</div>
        <FlexRowWrapper className="cta-wrapper">
          <ShareIcon />
          <EyeIcon />
          <CustomDropdown
            labelElement={<div className="t-more">More</div>}
            optionsElement={
              <>
                {/* <div className="option">Burn</div>
                <div className="option" onClick={() => {}}>
                  Withdraw
                </div> */}
                <div
                  className="option"
                  onClick={() => {
                    navigate(`/wormhole/${tokenId}`);
                  }}
                >
                  Swap Chain
                </div>
              </>
            }
          />
        </FlexRowWrapper>
      </FlexRowWrapper>
      <FlexColumnWrapper className="nft-info-wrapper">
        <div className="t-name">
          {assetData?.asset?.[0]?.name || 'Maroon 5'}
        </div>
        <div className="t-description">
          {assetData?.asset?.[0]?.description ||
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.`}
        </div>

        <table>
          <tbody>
            <tr>
              <th>No. of owners</th>
              <td>50/300</td>
            </tr>
            <tr>
              <th>Purchase Date</th>
              <td>12/03/2022</td>
            </tr>
            <tr>
              <th>Rarity Score</th>
              <td>10%</td>
            </tr>
          </tbody>
        </table>
      </FlexColumnWrapper>
    </NftDetailsWrapper>
  );
};

export default NftDetails;
