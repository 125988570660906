import * as React from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
// import { toast } from 'react-toastify';

import {
  useGetAssetsByOwnerAddressQuery,
  // useGetUnrevealedAssetsByOwnerAddressQuery,
} from 'generated/graphql';
import { torusConnector } from 'utils/web3/connectors/torusConnector';

import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
import ArtistCard from 'components/common/cards/ArtistCard';
// import MysteryCard from 'components/common/cards/MysteryCard';
import Button from 'components/common/Button';

// import RevealMysteryBoxModal from 'components/modals/RevealMysteryBoxModal';

// import { mysteryBoxesData } from 'data/mysteryBoxesData';

const UserProfileWrapper = styled.div`
  padding-bottom: 40px;

  .mystery-box-wrapper {
    padding: 0 24px;
    column-gap: 8px;
    overflow-x: auto;
    margin: 20px 0;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .t-title {
    margin-top: 40px;
    font-weight: 900;
    font-size: 24px;
    padding-left: 24px;

    line-height: 112.02%;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .filters-wrapper {
    column-gap: 8px;
    overflow-x: auto;
    margin-top: 20px;
    padding-left: 24px;

    .active {
      background: #ffcc00;
    }
  }

  .artists-wrapper {
    margin-top: 40px;
    gap: 12px 8px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

interface UserProfileProps {}

const UserProfile = (_: UserProfileProps): JSX.Element => {
  // const [showRevealNowModal, setShowRevealNowModal] = React.useState(false);
  // const [selectedAssetId, setSelectedAssetId] = React.useState<
  //   number | undefined
  // >();

  const navigate = useNavigate();
  const { account, active, activate } = useWeb3React();

  const { data: revealedAssetsData } = useGetAssetsByOwnerAddressQuery({
    skip: !account,
    variables: {
      ownerAddress: account as string,
    },
  });

  React.useEffect(() => {
    if (!active) {
      activate(torusConnector(), console.error);
    }
  }, [active, activate]);

  // const { data: unrevealedAssetsData } =
  //   useGetUnrevealedAssetsByOwnerAddressQuery({
  //     skip: !account,
  //     variables: {
  //       ownerAddress: account as string,
  //     },
  //   });

  // const handleReveal = () => {
  //   try {
  //     console.log('reveal clicked for ', selectedAssetId);
  //   } catch (error: any) {
  //     console.log(error);
  //     toast.error(
  //       error?.[0]?.message || error?.message || JSON.stringify(error)
  //     );
  //   }
  // };

  return (
    <UserProfileWrapper>
      {/* <div className="t-title">Newly Purchased</div>
      <FlexRowWrapper className="mystery-box-wrapper">
        {unrevealedAssetsData?.unrevealed_asset.map((asset, i) => (
          <MysteryCard
            key={i}
            name={asset?.name}
            //duration=""
            onRevealClick={() => {
              setSelectedAssetId(asset?.assetId);
              setShowRevealNowModal(true);
            }}
          />
        ))}
      </FlexRowWrapper> */}

      <div className="t-title">My Collection</div>
      <FlexRowWrapper className="filters-wrapper">
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
          className="active"
        >
          Legendary
        </Button>
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
        >
          Epic
        </Button>
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
        >
          Rare
        </Button>
        <Button
          onClick={() => console.log('filter button click')}
          type="filter"
        >
          Uncommon
        </Button>
      </FlexRowWrapper>
      <FlexRowWrapper className="artists-wrapper">
        {revealedAssetsData?.asset.map((asset, i) => (
          <ArtistCard
            key={i}
            isUser={true}
            name={asset?.name}
            onBuyNowClick={() => navigate(`/nft/${asset?.token_id}`)}
            profileImage={asset?.data_url}
            onSwapChainClick={() => navigate(`/wormhole/${asset?.token_id}`)}
          />
        ))}
      </FlexRowWrapper>
      {/* {showRevealNowModal && (
        <RevealMysteryBoxModal
          onClose={() => setShowRevealNowModal(false)}
          onRevealClick={handleReveal}
        />
      )} */}
    </UserProfileWrapper>
  );
};

export default UserProfile;
