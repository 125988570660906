import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';

import config from 'config';
import mysteryBoxArtifacts from 'artifacts/mysteryBox.json';

export const mysteryBoxContract = (library: Web3Provider) => {
  const contract = new Contract(
    config.blockchainAddresses.mysteryBox,
    mysteryBoxArtifacts.abi,
    library.getSigner()
  );
  return contract;
};
