import styled from 'styled-components/macro';
import FlexColumnWrapper from './wrappers/FlexColumnWrapper';

const LabelElementWrapper = styled(FlexColumnWrapper)`
  row-gap: 8px;
  margin-bottom: 16px;
  .t-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.8;
  }
  .element {
    height: 100%;
  }
`;

interface LabelElementProps {
  label: string;
  element: JSX.Element;
}

const LabelElement = ({ label, element }: LabelElementProps): JSX.Element => {
  return (
    <LabelElementWrapper>
      <div className="t-label">{label}</div>
      <div className="element">{element}</div>
    </LabelElementWrapper>
  );
};

export default LabelElement;
