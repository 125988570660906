import {
  getForeignAssetEth,
  hexToUint8Array,
  nativeToHexString,
  ChainId,
} from '@certusone/wormhole-sdk';
import ethers from 'ethers';

const checkWrappedNftContractPresent = async ({
  nftBridgeAddress,
  provider,
  originChainId,
  tokenAddress,
}: {
  nftBridgeAddress: string;
  provider: ethers.providers.Provider;
  originChainId: ChainId;
  tokenAddress: string;
}) => {
  const eth_addr = await getForeignAssetEth(
    nftBridgeAddress,
    provider,
    originChainId,
    hexToUint8Array(nativeToHexString(tokenAddress, originChainId) || '')
  );
  if (!eth_addr) {
    throw new Error('Ethereum address is null');
  } else {
    return eth_addr;
  }
};

export { checkWrappedNftContractPresent };
