import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  concat,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';

import { store } from 'store';
import { getLibrary } from 'utils/web3/getLibrary';

import App from './App';

import reportWebVitals from './reportWebVitals';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  headers: {
    'x-hasura-admin-secret': process.env.REACT_APP_X_HASURA_ADMIN_SECRET,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_HASURA_SUBSCRIPTIONS_URL as string,
  })
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
    },
  }));

  return forward(operation);
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, splitLink),
  credentials: 'allow',
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </Router>
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
