import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { CreateProfileMutation, GetProfileQuery } from 'generated/graphql';

interface UserState {
  walletAddress: string;
  isTokenGenerated: boolean;
  isUserLoggedIn: boolean;
  // userProfile?:
  //   | GetProfileQuery['user_by_pk']
  //   | CreateProfileMutation['insert_user_one'];
  userProfile?: any;
}

const initialState: UserState = {
  walletAddress: '',
  isTokenGenerated: false,
  isUserLoggedIn: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTokenGenerated: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isTokenGenerated: action.payload,
    }),
    setWalletAddress: (state, action: PayloadAction<string>) => ({
      ...state,
      walletAddress: action.payload,
    }),
    setProfile: (state, action: PayloadAction<{}>) => ({
      ...state,
      isUserLoggedIn: true,
      ...action.payload,
    }),
    logOutUser: () => ({
      ...initialState,
    }),
  },
});

export const { setTokenGenerated, setWalletAddress, setProfile, logOutUser } =
  userSlice.actions;

export default userSlice;
