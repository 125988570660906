import clsx from 'clsx';
import styled from 'styled-components/macro';

const ButtonWrapper = styled.button`
  cursor: pointer;
  background: inherit;
  border: none;
  width: fit-content;
  height: fit-content;
  border-radius: 16px;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  &.primary {
    background: #ffcc00;
  }

  &.tertiary {
    background: #ffffff;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 112.02%;
    padding: 8px 16px;
    color: #000000;
  }

  &.secondary {
    padding: 8px;
    width: fit-content;
    background: #000000;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 112.02%;
    color: #ffffff;
  }

  &.filter {
    font-size: 14px;
    line-height: 14px;
    width: fit-content;
    height: fit-content;
    letter-spacing: -0.18px;
    color: #a3a3a3;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.18px;
    padding: 8px 16px;
    border: 1px solid #404040;
    background: transparent;
    &.active {
      background: #ffffff;
      color: #000000;
    }
  }

  &.text {
    font-weight: 700;
    font-size: 14px;
    line-height: 152.02%;
    /* or 21px */
    background: transparent;
    color: #ffffff;
  }
`;

interface ButtonProps {
  children: string | JSX.Element | JSX.Element[];
  type?: 'primary' | 'secondary' | 'tertiary' | 'filter' | 'text';
  className?: string;
  onClick: () => void;
  disabled?: boolean;
}

const Button = ({
  children,
  type = 'primary',
  onClick,
  className,
  disabled = false,
}: ButtonProps): JSX.Element => {
  return (
    <ButtonWrapper
      disabled={disabled}
      onClick={onClick}
      className={`${className}  ${type}`}
    >
      {children}
    </ButtonWrapper>
  );
};

export default Button;
