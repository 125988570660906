import * as React from 'react';
import styled from 'styled-components/macro';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from 'react-icons/hi';

import FlexRowWrapper from 'components/common/wrappers/FlexRowWrapper';
import Button from 'components/common/Button';

const CheckoutTableWrapper = styled.div`
  margin-top: 16px;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    font-weight: 700;
    font-size: 14px;
    line-height: 152.02%;
    color: #ffffff;
    padding: 12px;
  }
  td {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 12px;
    opacity: 0.8;
  }
  tr {
    text-align: center;
    border: 1px solid #dddddd;
  }
  .ticket-count-wrapper {
    align-items: center;
    column-gap: 16px;
    .ticket-button {
      border-radius: 42px;
      width: 40px;
      height: 40px;
      padding: 0;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

interface CheckoutTableProps {
  quantity?: number;
}

const CheckoutTable = ({ quantity = 1 }: CheckoutTableProps): JSX.Element => {
  const [ticketCount, setTicketCount] = React.useState(quantity ?? 1);

  return (
    <CheckoutTableWrapper>
      <table>
        <tbody>
          <tr>
            <th>Box Name</th>
            <th>Quantity</th>
          </tr>

          <tr>
            <td>Maroon 5 World tour</td>
            <td>
              <FlexRowWrapper className="ticket-count-wrapper">
                <Button
                  onClick={() =>
                    setTicketCount(ticketCount === 1 ? 1 : ticketCount - 1)
                  }
                  type="tertiary"
                  className="ticket-button"
                >
                  <HiOutlineMinusCircle />
                </Button>
                <div className="t-ticket-count"> {ticketCount}</div>
                <Button
                  onClick={() => setTicketCount(ticketCount + 1)}
                  type="tertiary"
                  className="ticket-button"
                >
                  <HiOutlinePlusCircle />
                </Button>
              </FlexRowWrapper>
            </td>
          </tr>
        </tbody>
      </table>
    </CheckoutTableWrapper>
  );
};

export default CheckoutTable;
