import styled, { useTheme } from 'styled-components/macro';
import { Modal } from 'react-responsive-modal';
import { IoMdClose } from 'react-icons/io';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { hideConnectWalletModal } from 'reducers/modalsControllerSlice';

const ModalContentWrapper = styled.div`
  h3 {
    font-size: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.text[200]};
    padding: 20px;
  }

  p {
    padding: 20px;

    &:first-of-type {
      font-size: 20px;
      padding: 50px 20px;
      border-bottom: 1px solid ${({ theme }) => theme.text[300]};
    }
  }

  a {
    color: ${({ theme }) => theme.accent.primary[100]};
    text-decoration: underline;
  }
`;

const ConnectWalletModal = (): JSX.Element => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const showConnectWalletModal = useAppSelector(
    (state) => state.modalsController.showConnectWalletModal
  );

  return (
    <Modal
      open={showConnectWalletModal}
      onClose={() => dispatch(hideConnectWalletModal())}
      center
      styles={{
        modalContainer: {
          background: 'rgba(255, 255, 255, 0.1)',
        },
        modal: {
          padding: 0,
          background: theme.background[100],
          color: theme.text[100],
          width: '80%',
          maxWidth: '500px',
          borderRadius: '10px',
        },
      }}
      closeIcon={<IoMdClose style={{ width: 32, height: 32 }} color="white" />}
    >
      <ModalContentWrapper>
        <h3>MetaMask Account Required!</h3>
        <p>Log into your MetaMask Wallet to continue.</p>
        <p>
          Or download the extension from{' '}
          <a
            href="https://metamask.io/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://metamask.io/download
          </a>
        </p>
      </ModalContentWrapper>
    </Modal>
  );
};

export default ConnectWalletModal;
